import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {useGet} from "restful-react";
import {useSnackbar} from "notistack";
import {LoadingPaper} from "../../../components";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescentOutlined";
import {Measure, MeasureAttributes} from "./MeasureModel";

const MeasureSummaryField = ({value, label}) => {
  return <TextField InputProps={{
    readOnly: true
  }}
                    margin='dense'
                    fullWidth
                    label={label}
                    value={value}/>;
};

const MeasureSummaryData = ({project}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {
    data,
    loading,
    error
  } = useGet(`/data/projects/${project.id}/measure_summary`);
  useEffect(() => {
    if (error) {
      enqueueSnackbar("Cannot load measure Summary", {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);
  return (
    !data || !data.measure_summary || loading ?
    <LoadingPaper/> :
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <MeasureSummaryField value={data.measure_summary.Quantity}
                             label={Measure.Quantity.description}/>
      </Grid>
      {
        Object.keys(data.measure_summary.Attributes).map((attr) => {
          return (
            <Grid item xs={12} md={6}>
              <MeasureSummaryField value={data.measure_summary.Attributes[attr]}
                                   label={MeasureAttributes.all.attributes[attr].description}/>
            </Grid>
          );
        })
      }
    </Grid>
  );
};

const MeasureSummary = ({project}) => {
  return (
    <ExpansionPanel TransitionProps={{mountOnEnter: true, unmountOnExit: true}}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
        <Grid container spacing={1} direction="row" alignItems="center">
          <Grid item>
            <WbIncandescentIcon/>
          </Grid>
          <Grid item>
            <Typography>Measure Summary</Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <MeasureSummaryData project={project}/>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default MeasureSummary;
