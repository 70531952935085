import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import {useUser} from "../../../../../../context/user_context";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root:   {
    display:       'flex',
    flexDirection: 'column',
    alignItems:    'center',
    minHeight:     'fit-content'
  },
  avatar: {
    width:  60,
    height: 60
  },
  name:   {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const {className, ...rest} = props;

  const classes = useStyles();

  const user = useUser();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Link to='/user' style={{textDecoration: 'none'}}>
        <Typography
          className={classes.name}
          variant="h4"
        >
          {user.display_name}
        </Typography>
      </Link>
      <Typography variant="body2">{user.title}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
