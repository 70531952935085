/**
 * Sorts the object data to the same key order as reference
 * @param data {Object} Data to be sorted
 * @param reference {Object} Data to be referenced
 * @return {Object} data, sorted
 */
export function sort_object_keys_same(data, reference) {
  const key_order_cache =
    new Map(Object.keys(reference)
                  .reduce((a, i, idx) => [...a, [i, idx]], []));
  return Object.keys(data)
               .sort((a, b) =>
                       (key_order_cache.has(a) ? key_order_cache.get(a) : Number.MAX_SAFE_INTEGER) -
                       (key_order_cache.has(b) ? key_order_cache.get(b) : Number.MAX_SAFE_INTEGER))
               .reduce((a, i) => ({...a, [i]: data[i]}), {});
}
