const LOCATIONS = [
  'Bedroom',
  'Dining Room',
  'Hallway',
  'Kitchen',
  'Living Room',
  'Basement',
  'Garage',
  'In-Unit',
  'Common Area',
  'Ground',
  'Patio',
  'Elevated',
  'Roof',
  'Other',
  'Outdoor',
  'Conditioned Space',
  'Unconditioned Space',
  'Unconditioned Attic',
  'Public Restroom',
  'Private Restroom',
  'Interior',
  'Wall',
  'Outside Wall',
  'Outside Unit',
  'Inside Bldg'
];

const MEASURE_STATUS = [
  'Installed',
  'Removed',
  'Serviced',
  'Cancelled',
  'Recommended',
  'Rejected',
  'Missing at Site',
  'Move Out',
  'Move In'
];

export const Measure = {
  /*MeasureExternalId:        {
    type:        'text',
    description: 'The External Id for the Measure',
    required:    true,
    hidden:      false,
    validate:    {
      presence: {allowEmpty: false, message: 'is required'}
    }
  },*/
  ProgramMeasureExternalId: {
    type:        'text',
    description: 'The External Id for the Measure',
    required:    true,
    hidden:      false,
    validate:    {
      presence: {allowEmpty: false, message: 'is required'}
    }
  },
  Quantity:                 {
    type:        'number',
    description: '# of items for the measure',
    required:    true,
    hidden:      false,
    validate:    {
      presence:     {allowEmpty: false, message: 'is required'},
      numericality: true
    }
  },
  Category:                 {type: 'text', description: 'Category', required: false, hidden: true},
  Cost:                     {type: 'number', description: 'Equipment Cost', required: false, hidden: false},
  FactorDesc3:              {
    type: 'text',
    description: 'Gas Utility',
    required: false,
    hidden: true
  },
  FactorValue3:             {
    type: 'text',
    description: 'Gas Utility Name',
    required: false,
    hidden: true
  },
  Status:                   {
    type:        'select',
    description: 'Status of the measure',
    required:    true,
    hidden:      false,
    validate:    {
      presence:  {allowEmpty: false, message: 'is required'},
      inclusion: MEASURE_STATUS
    },
    values:      MEASURE_STATUS.map((status) => ({value: status, display: status}))
  },
  SerialNumber:             {type: 'text', description: 'Measures Serial Number', required: false, hidden: false},
  Type:                     {type: 'text', description: 'Type', required: false, hidden: true},
  Manufacturer:             {type: 'text', description: 'Manufacturer', required: false, hidden: true},
  Model:                    {type: 'text', description: 'Model', required: false, hidden: true},
  Space:                    {
    type:        'select',
    description: 'Location',
    required:    false,
    hidden:      false,
    validate:    {
      inclusion: LOCATIONS
    },
    values:      LOCATIONS.map((location) => ({value: location, display: location}))
  },
  TRMRefID:                 {
    type:        'text',
    description: 'TRM Key identifier',
    required:    true,
    hidden:      false,
    validate:    {
      presence: {allowEmpty: false, message: 'is required'}
    }
  },
  TRMDescription:           {type: 'text', description: 'TRM Description', required: false, hidden: false}
};

export const MeasureAttributes = {
  all:          {
    description: 'All',
    attributes:  {
      MeasureCost:                     {
        type:        'number',
        description: 'Measure Cost',
        required:    false,
        hidden:      false
      },
      MeasureLife:                     {
        type:        'number',
        description: 'Measure Life',
        required:    false,
        hidden:      false
      },
      Partner:                         {
        type:        'text',
        description: 'Partner',
        required:    false,
        hidden:      false
      },
      PartnerIncentive:                {
        type:        'number',
        description: 'Partner Incentive',
        required:    false,
        hidden:      false
      },
      SafetyHealthMeasuresEncountered: {
        type:        'select',
        description: 'Safety & Health Measures Encountered (Y/N)',
        required:    false,
        hidden:      false,
        validate:    {
          YesOrNo: true
        },
        values:      [{value: 'Y', display: 'Yes'}, {value: 'N', display: 'No'}]
      },
      SafetyHealthMeasuresInstalled:   {
        type:        'select',
        description: 'Safety & Heath Measures Installed (Y/N)',
        required:    false,
        hidden:      false,
        validate:    {
          YesOrNo: true
        },
        values:      [{value: 'Y', display: 'Yes'}, {value: 'N', display: 'No'}]
      },
      ServicePerformed:                {
        type:        'text',
        description: 'Service Performed',
        required:    false,
        hidden:      false
      },
      SavingskWCooling:                {
        type:        'number',
        description: 'Total coincident kW Saved from Cooling',
        required:    false,
        hidden:      false
      },
      SavingskWhCooling:               {
        type:        'number',
        description: 'Total kWh Savings from Cooling',
        required:    false,
        hidden:      false
      },
      SavingskWhHeating:               {
        type:        'number',
        description: 'Total kWh Savings from Heating',
        required:    false,
        hidden:      false
      },
      MeasureNTGR:                     {
        type:        'number',
        description: 'Net to Gross Ratio (as decimal)',
        required:    false,
        hidden:      false
      },
      SavingsGrossMeasureLife09Yr:     {
        type:        'number',
        description: 'EoL Gross kW per Ameren TRM for measures with a life of 0-9 years',
        required:    false,
        hidden:      false
      },
      SavingsGrossMeasureLife1014Yr:   {
        type:        'number',
        description: 'EoL Gross kW per Ameren TRM for measures with a life of 10-14 years',
        required:    false,
        hidden:      false
      },
      SavingsGrossMeasureLife15Yr:     {
        type:        'number',
        description: 'EoL Gross kW per Ameren TRM for measures with a life >= 15 years',
        required:    false,
        hidden:      false
      },
      SavingskW:                       {
        type:        'number',
        description: 'Total coincident kW Saved',
        required:    false,
        hidden:      false
      },
      SavingskWh:                      {
        type:        'number',
        description: 'Total kWh Savings',
        required:    false,
        hidden:      false
      },
      SavingsGross1StYearkWhTotal:     {
        type:        'number',
        description: 'First year Gross kWh per Ameren TRM',
        required:    false,
        hidden:      false
      },
      SavingsGross1StYearkWTotal:      {
        type:        'number',
        description: 'First year Gross kW per Ameren TRM',
        required:    false,
        hidden:      false
      },
      SavingsNetTherms:                   {
        type:        'number',
        description: 'First year Net Therms per Submittal Tool',
        required:    false,
        hidden:      true
      },
      SavingsGrossTherms:                 {
        type:        'number',
        description: 'First year Gross Therms per Ameren TRM',
        required:    false,
        hidden:      false
      },
      SavingsNetkW:                       {
        type:        'number',
        description: 'Net kW savings',
        required:    false,
        hidden:      false
      },
      SavingsNetkWh:                      {
        type:        'number',
        description: 'Net kWh Savings',
        required:    false,
        hidden:      false
      }
    }
  },
  types:        {
    lighting:                 {
      description: 'Lighting',
      attributes:  {
        DI:                 {
          type:        'text',
          description: 'Direct Installed or Leave Behind (Y/N)',
          required:    false,
          hidden:      false
        },
        Hours:              {
          type:        'number',
          description: 'Hours',
          required:    false,
          hidden:      false
        },
        Wattage:            {
          type:        'number',
          description: 'Baseline Wattage',
          required:    false,
          hidden:      false
        },
        EfficientWattage:   {
          type:        'number',
          description: 'Efficient Wattage',
          required:    false,
          hidden:      false
        },
        Lumens:             {
          type:        'number',
          description: 'Lumens',
          required:    false,
          hidden:      false
        },
        Dimmable:           {
          type:        'text',
          description: 'Dimmable (Y/N)',
          required:    false,
          hidden:      false
        },
        BulbsPerPack:       {
          type:        'number',
          description: 'Bulbs per Pack',
          required:    false,
          hidden:      true
        },
        NumberOfPacks:      {
          type:        'number',
          description: 'Number of Packs',
          required:    false,
          hidden:      true
        },
        IncentivePerPack:   {
          type:        'number',
          description: 'Incentive per Pack',
          required:    false,
          hidden:      true
        },
        BulbType:           {
          type:        'text',
          description: 'Bulb Type',
          required:    false,
          hidden:      true
        },
        ColorTemperature:   {
          type:        'number',
          description: 'Color Temperature',
          required:    false,
          hidden:      true
        },
        InteriorOrExterior: {
          type:        'text',
          description: 'Interior Or Exterior',
          required:    false,
          hidden:      true
        },
        BaselineBulb:       {
          type:        'text',
          description: 'Baseline Bulb',
          required:    false,
          hidden:      true
        }
      }
    },
    learning_thermostat:      {
      description: 'Learning Thermostats',
      attributes:  {
        BaselineHeatingEquipment:      {
          type:        'text',
          description: 'Baseline Heating Equipment',
          required:    false,
          hidden:      false
        },
        BaselineCoolingType:           {
          type:        'text',
          description: 'Baseline Cooling Type',
          required:    false,
          hidden:      false
        },
        CoolingCapacityBTUHr:          {
          type:        'number',
          description: 'Cooling Capacity (Btu/hr)',
          required:    false,
          hidden:      false
        },
        BaselineCoolingEfficiencySEER: {
          type:        'number',
          description: 'Baseline Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        }
      }
    },
    heat_pump_water_heater:   {
      description: 'Heat Pump & Water Heater',
      attributes:  {
        DHWEfficiency:                 {
          type:        'number',
          description: 'DHW Efficiency',
          required:    false,
          hidden:      false
        },
        DHWSupplyTemp:                 {
          type:        'number',
          description: 'DHW Supply Temp',
          required:    false,
          hidden:      false
        },
        DHWType:                       {
          type:        'text',
          description: 'DHW Type',
          required:    false,
          hidden:      false
        },
        NewDHWEfficiency:              {
          type:        'number',
          description: 'New DHW Efficiency',
          required:    false,
          hidden:      false
        },
        YearBuilt:                     {
          type:        'number',
          description: 'YearBuilt',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEquipment:      {
          type:        'text',
          description: 'Baseline Heating Equipment',
          required:    false,
          hidden:      false
        },
        BaselineCoolingType:           {
          type:        'text',
          description: 'Baseline Cooling Type',
          required:    false,
          hidden:      false
        },
        BaselineCoolingEfficiencySEER: {
          type:        'number',
          description: 'Baseline Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEfficiencyHSPF: {
          type:        'number',
          description: 'Baseline Heating Efficiency (HSPF)',
          required:    false,
          hidden:      false
        }
      }
    },
    central_air_conditioning: {
      description: 'Central Air',
      attributes:  {
        CoolingCapacityBTUHr:           {
          type:        'number',
          description: 'Cooling Capacity (Btu/hr)',
          required:    false,
          hidden:      false
        },
        BaselineCoolingEfficiencySEER:  {
          type:        'number',
          description: 'Baseline Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        EfficientCoolingEfficiencySEER: {
          type:        'number',
          description: 'Efficient Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        YearBuilt:                      {
          type:        'number',
          description: 'YearBuilt',
          required:    false,
          hidden:      false
        },
        EquipmentCondenserModel:        {
          type:        'text',
          description: 'Equipment Condenser Model #',
          required:    false,
          hidden:      false
        },
        EquipmentCondenserSerial:       {
          type:        'text',
          description: 'Equipment Condenser Serial #',
          required:    false,
          hidden:      false
        },
        EnergyStarACCEER:               {
          type:        'number',
          description: 'Energy Star AC CEER Value',
          required:    false,
          hidden:      true
        }
      }
    },
    ductless_heat_pump:       {
      description: 'Ductless Heat Pumps',
      attributes:  {
        CoolingCapacityBTUHr:           {
          type:        'number',
          description: 'Cooling Capacity (Btu/hr)',
          required:    false,
          hidden:      false
        },
        BaselineCoolingEfficiencySEER:  {
          type:        'number',
          description: 'Baseline Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        EfficientCoolingEfficiencySEER: {
          type:        'number',
          description: 'Efficient Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        HeatingCapacityBTUHr:           {
          type:        'number',
          description: 'Heating Capacity (Btu/hr)',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEfficiencyHSPF:  {
          type:        'number',
          description: 'Baseline Heating Efficiency (HSPF)',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEquipment:       {
          type:        'text',
          description: 'Baseline Heating Equipment',
          required:    false,
          hidden:      false
        },
        BaselineCoolingType:            {
          type:        'text',
          description: 'Baseline Cooling Type',
          required:    false,
          hidden:      false
        },
        EfficientHeatingEfficiencyHSPF: {
          type:        'number',
          description: 'Equipment Efficiency Rating (HSPF)',
          required:    false,
          hidden:      false
        },
        EquipmentCondenserModel:        {
          type:        'text',
          description: 'Equipment Condenser Model #',
          required:    false,
          hidden:      false
        },
        EquipmentCondenserSerial:       {
          type:        'text',
          description: 'Equipment Condenser Serial #',
          required:    false,
          hidden:      false
        },
        OutdoorBrand:                   {
          type:        'text',
          description: 'Outdoor Brand',
          required:    false,
          hidden:      false
        },
        OutdoorSerialNumber:            {
          type:        'text',
          description: 'Outdoor Serial #',
          required:    false,
          hidden:      true
        },
        YearBuilt:                      {
          type:        'number',
          description: 'YearBuilt',
          required:    false,
          hidden:      false
        }
      }
    },
    tune_up:                  {
      description: 'Tune Up',
      attributes:  {
        AdjustRefrigerantChargeTU1:       {
          type:        'text',
          description: 'Adjust refrigerant charge TU 1',
          required:    false,
          hidden:      true
        },
        CoolingCapacityBTUHr:             {
          type:        'number',
          description: 'Cooling Capacity (Btu/hr)',
          required:    false,
          hidden:      false
        },
        PreBlowMotorAmpsTU1:              {
          type:        'number',
          description: 'Pre Blow Motor Amps TU 1',
          required:    false,
          hidden:      false
        },
        PreBlowMotorVoltsTU1:             {
          type:        'number',
          description: 'Pre Blow Motor Volts TU 1',
          required:    false,
          hidden:      false
        },
        PreBlowMotorWattsTU1:             {
          type:        'number',
          description: 'Pre Blow Motor Watts TU 1',
          required:    false,
          hidden:      false
        },
        PreBTUTU1Equipment:               {
          type:        'number',
          description: 'Pre BTU TU 1 Equipment',
          required:    false,
          hidden:      false
        },
        PreCalculatedCFMTU1:              {
          type:        'number',
          description: 'Pre Calculated CFM TU 1',
          required:    false,
          hidden:      false
        },
        PreCFM:                           {
          type:        'number',
          description: 'Pre CFM',
          required:    false,
          hidden:      true
        },
        PreCoilEnterDBTU1:                {
          type:        'number',
          description: 'Pre Coil Enter DB TU 1',
          required:    false,
          hidden:      false
        },
        PreCoilEnterWBTU1:                {
          type:        'number',
          description: 'Pre Coil Enter WB TU 1',
          required:    false,
          hidden:      false
        },
        PreCoilLeaveDBTU1:                {
          type:        'number',
          description: 'Pre Coil Leave DB TU 1',
          required:    false,
          hidden:      false
        },
        PreCoilLeaveWBTU1:                {
          type:        'number',
          description: 'Pre Coil Leave WB TU 1',
          required:    false,
          hidden:      false
        },
        PreCompressorAmpsTU1:             {
          type:        'number',
          description: 'Pre Compressor Amps TU 1',
          required:    false,
          hidden:      false
        },
        PreCompressorVoltsTU1:            {
          type:        'number',
          description: 'Pre Compressor Volts TU 1',
          required:    false,
          hidden:      false
        },
        PreCompressorWattsTU1:            {
          type:        'number',
          description: 'Pre Compressor Watts TU 1',
          required:    false,
          hidden:      false
        },
        PreCondenserFanAmpsTU1:           {
          type:        'number',
          description: 'Pre Condenser Fan Amps TU 1',
          required:    false,
          hidden:      false
        },
        PreCondenserFanVoltsTU1:          {
          type:        'number',
          description: 'Pre Condenser Fan Volts TU 1',
          required:    false,
          hidden:      false
        },
        PreCondenserFanWattsTU1:          {
          type:        'number',
          description: 'Pre Condenser Fan Watts TU 1',
          required:    false,
          hidden:      false
        },
        PreEffectiveEfficiencyTU1:        {
          type:        'number',
          description: 'Pre Effective Efficiency TU 1',
          required:    false,
          hidden:      false
        },
        PreEnthalpyA:                     {
          type:        'number',
          description: 'Pre Enthalpy A',
          required:    false,
          hidden:      false
        },
        PreEnthalpyB:                     {
          type:        'number',
          description: 'Pre Enthalpy B',
          required:    false,
          hidden:      false
        },
        PreEnthalpyDiffTU1:               {
          type:        'number',
          description: 'Pre Enthalpy Diff TU 1',
          required:    false,
          hidden:      false
        },
        PreIndoorCoilEnteringConditions:  {
          type:        'number',
          description: 'Pre Indoor Coil Entering Conditions',
          required:    false,
          hidden:      false
        },
        PreIndoorCoilLeavingConditions:   {
          type:        'number',
          description: 'Pre Indoor Coil Leaving Conditions',
          required:    false,
          hidden:      false
        },
        PreNominalCapacityTU1:            {
          type:        'number',
          description: 'Pre Nominal Capacity TU 1',
          required:    false,
          hidden:      false
        },
        PreTotalSystemWattsTU1:           {
          type:        'number',
          description: 'Pre Total System Watts TU 1',
          required:    false,
          hidden:      false
        },
        PostBlowMotorAmpsTU1:             {
          type:        'number',
          description: 'Post Blow Motor Amps TU 1',
          required:    false,
          hidden:      false
        },
        PostBlowMotorVoltsTU1:            {
          type:        'number',
          description: 'Post Blow Motor Volts TU 1',
          required:    false,
          hidden:      false
        },
        PostBlowMotorWattsTU1:            {
          type:        'number',
          description: 'Post Blow Motor Watts TU 1',
          required:    false,
          hidden:      false
        },
        PostBTUTU1Equipment:              {
          type:        'number',
          description: 'Post BTU TU 1 Equipment',
          required:    false,
          hidden:      false
        },
        PostCalculatedCFMTU1:             {
          type:        'number',
          description: 'Post Calculated CFM TU 1',
          required:    false,
          hidden:      false
        },
        PostCFM:                          {
          type:        'number',
          description: 'Post CFM',
          required:    false,
          hidden:      true
        },
        PostCoilEnterDBTU1:               {
          type:        'number',
          description: 'Post Coil Enter DB TU 1',
          required:    false,
          hidden:      false
        },
        PostCoilEnterWBTU1:               {
          type:        'number',
          description: 'Post Coil Enter WB TU 1',
          required:    false,
          hidden:      false
        },
        PostCoilLeaveDBTU1:               {
          type:        'number',
          description: 'Post Coil Leave DB TU 1',
          required:    false,
          hidden:      false
        },
        PostCoilLeaveWBTU1:               {
          type:        'number',
          description: 'Post Coil Leave WB TU 1',
          required:    false,
          hidden:      false
        },
        PostCompressorAmpsTU1:            {
          type:        'number',
          description: 'Post Compressor Amps TU 1',
          required:    false,
          hidden:      false
        },
        PostCompressorVoltsTU1:           {
          type:        'number',
          description: 'Post Compressor Volts TU 1',
          required:    false,
          hidden:      false
        },
        PostCompressorWattsTU1:           {
          type:        'number',
          description: 'Post Compressor Watts TU 1',
          required:    false,
          hidden:      false
        },
        PostCondenserFanAmpsTU1:          {
          type:        'number',
          description: 'Post Condenser Fan Amps TU 1',
          required:    false,
          hidden:      false
        },
        PostCondenserFanVoltsTU1:         {
          type:        'number',
          description: 'Post Condenser Fan Volts TU 1',
          required:    false,
          hidden:      false
        },
        PostCondenserFanWattsTU1:         {
          type:        'number',
          description: 'Post Condenser Fan Watts TU 1',
          required:    false,
          hidden:      false
        },
        PostEffectiveEfficiencyTU1:       {
          type:        'number',
          description: 'Post Effective Efficiency TU 1',
          required:    false,
          hidden:      false
        },
        PostEnthalpyA:                    {
          type:        'number',
          description: 'Post Enthalpy A',
          required:    false,
          hidden:      false
        },
        PostEnthalpyB:                    {
          type:        'number',
          description: 'Post Enthalpy B',
          required:    false,
          hidden:      false
        },
        PostEnthalpyDiffTU1:              {
          type:        'number',
          description: 'Post Enthalpy Diff TU 1',
          required:    false,
          hidden:      false
        },
        PostFixedPressure1:               {
          type:        'number',
          description: 'Post Fixed Pressure 1',
          required:    false,
          hidden:      false
        },
        PostFixedTemp1:                   {
          type:        'number',
          description: 'Post Fixed Temp 1',
          required:    false,
          hidden:      false
        },
        PostIndoorCoilEnteringConditions: {
          type:        'number',
          description: 'Post Indoor Coil Entering Conditions',
          required:    false,
          hidden:      false
        },
        PostIndoorCoilLeavingConditions:  {
          type:        'number',
          description: 'Post Indoor Coil Leaving Conditions',
          required:    false,
          hidden:      false
        },
        PostmarkDate:                     {
          type:        'date',
          description: 'Post Mark Date',
          required:    false,
          hidden:      false
        },
        PostNominalCapacityTU1:           {
          type:        'number',
          description: 'Post Nominal Capacity TU 1',
          required:    false,
          hidden:      false
        },
        PostTotalSystemWattsTU1:          {
          type:        'number',
          description: 'Post Total System Watts TU 1',
          required:    false,
          hidden:      false
        },
        PostTXVPressure1:                 {
          type:        'number',
          description: 'Post TXV Pressure 1',
          required:    false,
          hidden:      true
        },
        PostTXVTemp1:                     {
          type:        'number',
          description: 'Post TXV Temp 1',
          required:    false,
          hidden:      true
        },
        OutdoorBrand:                     {
          type:        'text',
          description: 'Outdoor Brand',
          required:    false,
          hidden:      false
        },
        EquipmentCondenserModel:          {
          type:        'text',
          description: 'Equipment Condenser Model #',
          required:    false,
          hidden:      false
        },
        EquipmentCondenserSerial:         {
          type:        'text',
          description: 'Equipment Condenser Serial #',
          required:    false,
          hidden:      false
        }
      }
    },
    windows:                  {
      description: 'Windows',
      attributes:  {
        Ubase:                         {
          type:        'number',
          description: 'Ubase',
          required:    false,
          hidden:      false
        },
        Ueff:                          {
          type:        'number',
          description: 'Ueff',
          required:    false,
          hidden:      false
        },
        SHGCbase:                      {
          type:        'number',
          description: 'SHGCbase',
          required:    false,
          hidden:      false
        },
        SHGCeff:                       {
          type:        'number',
          description: 'SHGCeff',
          required:    false,
          hidden:      false
        },
        Awindow:                       {
          type:        'number',
          description: 'Awindow',
          required:    false,
          hidden:      false
        },
        BaselineCoolingEfficiencySEER: {
          type:        'number',
          description: 'Baseline Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEfficiencyHSPF: {
          type:        'number',
          description: 'Baseline Heating Efficiency (HSPF)',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEquipment:      {
          type:        'text',
          description: 'Baseline Heating Equipment',
          required:    false,
          hidden:      false
        },
        BaselineCoolingType:           {
          type:        'text',
          description: 'Baseline Cooling Type',
          required:    false,
          hidden:      false
        }
      }
    },
    duct_sealing:             {
      description: 'Duct Sealing',
      attributes:  {
        DuctLength:               {
          type:        'number',
          description: 'Duct Length',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEquipment: {
          type:        'text',
          description: 'Baseline Heating Equipment',
          required:    false,
          hidden:      false
        },
        BaselineCoolingType:      {
          type:        'text',
          description: 'Baseline Cooling Type',
          required:    false,
          hidden:      false
        }
      }
    },
    air_sealing:              {
      description: 'Air Sealing',
      attributes:  {
        AreaInsulatedSqft:        {
          type:        'number',
          description: 'Area Insulated Square Feet',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEquipment: {
          type:        'text',
          description: 'Baseline Heating Equipment',
          required:    false,
          hidden:      false
        },
        BaselineCoolingType:      {
          type:        'text',
          description: 'Baseline Cooling Type',
          required:    false,
          hidden:      false
        }
      }
    },
    refrigerator:             {
      description: 'Refrigerator',
      attributes:  {
        RefrigerationTemp:                {
          type:        'number',
          description: 'Refrigeration Temperature',
          required:    false,
          hidden:      true
        },
        RefrigeratorBasecaseManufacturer: {
          type:        'text',
          description: 'Refrigerator Basecase Manufacturer',
          required:    false,
          hidden:      true
        },
        RefrigeratorBasecaseModel:        {
          type:        'text',
          description: 'Refrigerator Basecase Model #',
          required:    false,
          hidden:      true
        },
        RefrigeratorBasecaseSize:         {
          type:        'text',
          description: 'Refrigerator Basecase Size',
          required:    false,
          hidden:      true
        },
        RefrigeratorBasecaseYear:         {
          type:        'number',
          description: 'Refrigerator Basecase Year',
          required:    false,
          hidden:      true
        },
        RefrigeratorNewEquipmentModel:    {
          type:        'text',
          description: 'Refrigerator New Equipment Model #',
          required:    false,
          hidden:      true
        },
        RefrigeratorNewEquipmentSerial:   {
          type:        'text',
          description: 'Refrigerator New Eqiupment Serial',
          required:    false,
          hidden:      true
        },
        RefrigeratorNewEquipmentSize:     {
          type:        'text',
          description: 'Refrigerator New Equipment Size',
          required:    false,
          hidden:      true
        },
        Age:                              {
          type:        'number',
          description: 'Age',
          required:    false,
          hidden:      false
        },
        Type:                             {
          type:        'text',
          description: 'Type',
          required:    false,
          hidden:      false
        },
        KwhNew:                           {
          type:        'number',
          description: 'kWh New',
          required:    false,
          hidden:      false
        },
        YearBuilt:                        {
          type:        'number',
          description: 'YearBuilt',
          required:    false,
          hidden:      false
        }
      }
    },
    water_fixtures:           {
      description: 'Water Fixtures',
      attributes:  {
        ExistingGPM:   {
          type:        'number',
          description: 'Existing GPM',
          required:    false,
          hidden:      false
        },
        InstalledGPM:  {
          type:        'number',
          description: 'Installed GPM',
          required:    false,
          hidden:      false
        },
        DHWEfficiency: {
          type:        'number',
          description: 'DHW Efficiency',
          required:    false,
          hidden:      false
        },
        DHWSupplyTemp: {
          type:        'number',
          description: 'DHW Supply Temp',
          required:    false,
          hidden:      false
        },
        DHWType:       {
          type:        'text',
          description: 'DHW Type',
          required:    false,
          hidden:      false
        },
        YearBuilt:     {
          type:        'number',
          description: 'YearBuilt',
          required:    false,
          hidden:      false
        }
      }
    },
    pipe_insulation:          {
      description: 'Pipe Insulation',
      attributes:  {
        DHWEfficiency:                {
          type:        'number',
          description: 'DHW Efficiency',
          required:    false,
          hidden:      false
        },
        BaselinePipeCircumferenceFt:  {
          type:        'number',
          description: 'Baseline Pipe Circumference (ft)',
          required:    false,
          hidden:      false
        },
        InsulatedPipeCircumferenceFt: {
          type:        'number',
          description: 'Insulated Pipe Circumference (ft)',
          required:    false,
          hidden:      false
        }
      }
    },
    ceiling_insulation:       {
      description: 'Ceiling Insulation',
      attributes:  {
        ExistingRValue:                {
          type:        'number',
          description: 'Existing R Value',
          required:    false,
          hidden:      false
        },
        EfficientRValue:               {
          type:        'number',
          description: 'Efficient R Value',
          required:    false,
          hidden:      false
        },
        AreaInsulatedSqft:             {
          type:        'number',
          description: 'Area Insulated Square Feet',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEquipment:      {
          type:        'text',
          description: 'Baseline Heating Equipment',
          required:    false,
          hidden:      false
        },
        BaselineCoolingType:           {
          type:        'text',
          description: 'Baseline Cooling Type',
          required:    false,
          hidden:      false
        },
        BaselineCoolingEfficiencySEER: {
          type:        'number',
          description: 'Baseline Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEfficiencyHSPF: {
          type:        'number',
          description: 'Baseline Heating Efficiency (HSPF)',
          required:    false,
          hidden:      false
        },
        NewASHP:                       {
          type:        'text',
          description: 'New ASHP',
          required:    false,
          hidden:      true
        },
        NewCentralCooling:             {
          type:        'text',
          description: 'New Central Cooling',
          required:    false,
          hidden:      true
        }
      }
    },
    pool_pump:                {
      description: 'Pool Pump',
      attributes:  {
        Daysoper:  {
          type:        'number',
          description: 'Days Operating',
          required:    false,
          hidden:      false
        },
        GPMss:     {
          type:        'number',
          description: 'GPMss',
          required:    false,
          hidden:      false
        },
        RTss:      {
          type:        'number',
          description: 'RTss',
          required:    false,
          hidden:      false
        },
        GPMhs:     {
          type:        'number',
          description: 'GPMhs',
          required:    false,
          hidden:      false
        },
        RThs:      {
          type:        'number',
          description: 'RThs',
          required:    false,
          hidden:      false
        },
        GPMls:     {
          type:        'number',
          description: 'GPMls',
          required:    false,
          hidden:      false
        },
        RTls:      {
          type:        'number',
          description: 'RTls',
          required:    false,
          hidden:      false
        },
        PoolSize:  {
          type:        'text',
          description: 'Pool Size',
          required:    false,
          hidden:      true
        },
        YearBuilt: {
          type:        'number',
          description: 'YearBuilt',
          required:    false,
          hidden:      false
        }
      }
    },

    ecm_motors:     {
      description: 'ECM Motors',
      attributes:  {
        FanType:                         {
          type:        'text',
          description: 'Fan Type',
          required:    false,
          hidden:      false
        },
        FurnaceOrAirHandlerManufacturer: {
          type:        'text',
          description: 'Furnace Or Air Handler Manufacturer',
          required:    false,
          hidden:      false
        },
        FurnaceOrAirHandlerModel:        {
          type:        'text',
          description: 'Furnace Or Air Handler Model #',
          required:    false,
          hidden:      false
        },
        NewASHP:                         {
          type:        'text',
          description: 'New ASHP',
          required:    false,
          hidden:      false
        },
        NewCentralCooling:               {
          type:        'text',
          description: 'New Central Cooling',
          required:    false,
          hidden:      false
        }
      }
    },
    custom_measure: {
      description: 'Custom Measure',
      attributes:  {
        AdjustRefrigerantChargeTU1:       {
          type:        'text',
          description: 'Adjust refrigerant charge TU 1',
          required:    false,
          hidden:      true
        },
        Age:                              {
          type:        'number',
          description: 'Age',
          required:    false,
          hidden:      false
        },
        AreaInsulatedSqft:                {
          type:        'number',
          description: 'Area Insulated Square Feet',
          required:    false,
          hidden:      false
        },
        Awindow:                          {
          type:        'number',
          description: 'Awindow',
          required:    false,
          hidden:      false
        },
        BaselineBulb:                     {
          type:        'text',
          description: 'Baseline Bulb',
          required:    false,
          hidden:      true
        },
        BaselineCoolingEfficiencySEER:    {
          type:        'number',
          description: 'Baseline Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        BaselineCoolingType:              {
          type:        'text',
          description: 'Baseline Cooling Type',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEfficiencyHSPF:    {
          type:        'number',
          description: 'Baseline Heating Efficiency (HSPF)',
          required:    false,
          hidden:      false
        },
        BaselineHeatingEquipment:         {
          type:        'text',
          description: 'Baseline Heating Equipment',
          required:    false,
          hidden:      false
        },
        BaselinePipeCircumferenceFt:      {
          type:        'number',
          description: 'Baseline Pipe Circumference (ft)',
          required:    false,
          hidden:      false
        },
        BulbType:                         {
          type:        'text',
          description: 'Bulb Type',
          required:    false,
          hidden:      true
        },
        BulbsPerPack:                     {
          type:        'number',
          description: 'Bulbs per Pack',
          required:    false,
          hidden:      true
        },
        ColorTemperature:                 {
          type:        'number',
          description: 'Color Temperature',
          required:    false,
          hidden:      true
        },
        CoolingCapacityBTUHr:             {
          type:        'number',
          description: 'Cooling Capacity (Btu/hr)',
          required:    false,
          hidden:      false
        },
        DHWEfficiency:                    {
          type:        'number',
          description: 'DHW Efficiency',
          required:    false,
          hidden:      false
        },
        DHWSupplyTemp:                    {
          type:        'number',
          description: 'DHW Supply Temp',
          required:    false,
          hidden:      false
        },
        DHWType:                          {
          type:        'text',
          description: 'DHW Type',
          required:    false,
          hidden:      false
        },
        DI:                               {
          type:        'text',
          description: 'Direct Installed or Leave Behind (Y/N)',
          required:    false,
          hidden:      false
        },
        Daysoper:                         {
          type:        'number',
          description: 'Days Operating',
          required:    false,
          hidden:      false
        },
        Dimmable:                         {
          type:        'text',
          description: 'Dimmable (Y/N)',
          required:    false,
          hidden:      false
        },
        DuctLength:                       {
          type:        'number',
          description: 'Duct Length',
          required:    false,
          hidden:      false
        },
        EfficientCoolingEfficiencySEER:   {
          type:        'number',
          description: 'Efficient Cooling Efficiency (SEER)',
          required:    false,
          hidden:      false
        },
        EfficientHeatingEfficiencyHSPF:   {
          type:        'number',
          description: 'Equipment Efficiency Rating (HSPF)',
          required:    false,
          hidden:      false
        },
        EfficientRValue:                  {
          type:        'number',
          description: 'Efficient R Value',
          required:    false,
          hidden:      false
        },
        EfficientWattage:                 {
          type:        'number',
          description: 'Efficient Wattage',
          required:    false,
          hidden:      false
        },
        EnergyStarACCEER:                 {
          type:        'number',
          description: 'Energy Star AC CEER Value',
          required:    false,
          hidden:      true
        },
        EquipmentCondenserModel:          {
          type:        'text',
          description: 'Equipment Condenser Model #',
          required:    false,
          hidden:      false
        },
        EquipmentCondenserSerial:         {
          type:        'text',
          description: 'Equipment Condenser Serial #',
          required:    false,
          hidden:      false
        },
        ExistingGPM:                      {
          type:        'number',
          description: 'Existing GPM',
          required:    false,
          hidden:      false
        },
        ExistingRValue:                   {
          type:        'number',
          description: 'Existing R Value',
          required:    false,
          hidden:      false
        },
        FanType:                          {
          type:        'text',
          description: 'Fan Type',
          required:    false,
          hidden:      false
        },
        FurnaceOrAirHandlerManufacturer:  {
          type:        'text',
          description: 'Furnace Or Air Handler Manufacturer',
          required:    false,
          hidden:      false
        },
        FurnaceOrAirHandlerModel:         {
          type:        'text',
          description: 'Furnace Or Air Handler Model #',
          required:    false,
          hidden:      false
        },
        GPMhs:                            {
          type:        'number',
          description: 'GPMhs',
          required:    false,
          hidden:      false
        },
        GPMls:                            {
          type:        'number',
          description: 'GPMls',
          required:    false,
          hidden:      false
        },
        GPMss:                            {
          type:        'number',
          description: 'GPMss',
          required:    false,
          hidden:      false
        },
        HeatingCapacityBTUHr:             {
          type:        'number',
          description: 'Heating Capacity (Btu/hr)',
          required:    false,
          hidden:      false
        },
        Hours:                            {
          type:        'number',
          description: 'Hours',
          required:    false,
          hidden:      false
        },
        IncentivePerPack:                 {
          type:        'number',
          description: 'Incentive per Pack',
          required:    false,
          hidden:      true
        },
        InstalledGPM:                     {
          type:        'number',
          description: 'Installed GPM',
          required:    false,
          hidden:      false
        },
        InsulatedPipeCircumferenceFt:     {
          type:        'number',
          description: 'Insulated Pipe Circumference (ft)',
          required:    false,
          hidden:      false
        },
        InteriorOrExterior:               {
          type:        'text',
          description: 'Interior Or Exterior',
          required:    false,
          hidden:      true
        },
        KwhNew:                           {
          type:        'number',
          description: 'kWh New',
          required:    false,
          hidden:      false
        },
        Lumens:                           {
          type:        'number',
          description: 'Lumens',
          required:    false,
          hidden:      false
        },
        NewASHP:                          {
          type:        'text',
          description: 'New ASHP',
          required:    false,
          hidden:      false
        },
        NewCentralCooling:                {
          type:        'text',
          description: 'New Central Cooling',
          required:    false,
          hidden:      false
        },
        NewDHWEfficiency:                 {
          type:        'number',
          description: 'New DHW Efficiency',
          required:    false,
          hidden:      false
        },
        NumberOfPacks:                    {
          type:        'number',
          description: 'Number of Packs',
          required:    false,
          hidden:      true
        },
        OutdoorBrand:                     {
          type:        'text',
          description: 'Outdoor Brand',
          required:    false,
          hidden:      false
        },
        OutdoorSerialNumber:              {
          type:        'text',
          description: 'Outdoor Serial #',
          required:    false,
          hidden:      true
        },
        PoolSize:                         {
          type:        'text',
          description: 'Pool Size',
          required:    false,
          hidden:      true
        },
        PostBTUTU1Equipment:              {
          type:        'number',
          description: 'Post BTU TU 1 Equipment',
          required:    false,
          hidden:      true
        },
        PostBlowMotorAmpsTU1:             {
          type:        'number',
          description: 'Post Blow Motor Amps TU 1',
          required:    false,
          hidden:      true
        },
        PostBlowMotorVoltsTU1:            {
          type:        'number',
          description: 'Post Blow Motor Volts TU 1',
          required:    false,
          hidden:      true
        },
        PostBlowMotorWattsTU1:            {
          type:        'number',
          description: 'Post Blow Motor Watts TU 1',
          required:    false,
          hidden:      true
        },
        PostCFM:                          {
          type:        'number',
          description: 'Post CFM',
          required:    false,
          hidden:      true
        },
        PostCalculatedCFMTU1:             {
          type:        'number',
          description: 'Post Calculated CFM TU 1',
          required:    false,
          hidden:      true
        },
        PostCoilEnterDBTU1:               {
          type:        'number',
          description: 'Post Coil Enter DB TU 1',
          required:    false,
          hidden:      true
        },
        PostCoilEnterWBTU1:               {
          type:        'number',
          description: 'Post Coil Enter WB TU 1',
          required:    false,
          hidden:      true
        },
        PostCoilLeaveDBTU1:               {
          type:        'number',
          description: 'Post Coil Leave DB TU 1',
          required:    false,
          hidden:      true
        },
        PostCoilLeaveWBTU1:               {
          type:        'number',
          description: 'Post Coil Leave WB TU 1',
          required:    false,
          hidden:      true
        },
        PostCompressorAmpsTU1:            {
          type:        'number',
          description: 'Post Compressor Amps TU 1',
          required:    false,
          hidden:      true
        },
        PostCompressorVoltsTU1:           {
          type:        'number',
          description: 'Post Compressor Volts TU 1',
          required:    false,
          hidden:      true
        },
        PostCompressorWattsTU1:           {
          type:        'number',
          description: 'Post Compressor Watts TU 1',
          required:    false,
          hidden:      true
        },
        PostCondenserFanAmpsTU1:          {
          type:        'number',
          description: 'Post Condenser Fan Amps TU 1',
          required:    false,
          hidden:      true
        },
        PostCondenserFanVoltsTU1:         {
          type:        'number',
          description: 'Post Condenser Fan Volts TU 1',
          required:    false,
          hidden:      true
        },
        PostCondenserFanWattsTU1:         {
          type:        'number',
          description: 'Post Condenser Fan Watts TU 1',
          required:    false,
          hidden:      true
        },
        PostEffectiveEfficiencyTU1:       {
          type:        'number',
          description: 'Post Effective Efficiency TU 1',
          required:    false,
          hidden:      true
        },
        PostEnthalpyA:                    {
          type:        'number',
          description: 'Post Enthalpy A',
          required:    false,
          hidden:      true
        },
        PostEnthalpyB:                    {
          type:        'number',
          description: 'Post Enthalpy B',
          required:    false,
          hidden:      true
        },
        PostEnthalpyDiffTU1:              {
          type:        'number',
          description: 'Post Enthalpy Diff TU 1',
          required:    false,
          hidden:      true
        },
        PostFixedPressure1:               {
          type:        'number',
          description: 'Post Fixed Pressure 1',
          required:    false,
          hidden:      true
        },
        PostFixedTemp1:                   {
          type:        'number',
          description: 'Post Fixed Temp 1',
          required:    false,
          hidden:      true
        },
        PostIndoorCoilEnteringConditions: {
          type:        'number',
          description: 'Post Indoor Coil Entering Conditions',
          required:    false,
          hidden:      true
        },
        PostIndoorCoilLeavingConditions:  {
          type:        'number',
          description: 'Post Indoor Coil Leaving Conditions',
          required:    false,
          hidden:      true
        },
        PostNominalCapacityTU1:           {
          type:        'number',
          description: 'Post Nominal Capacity TU 1',
          required:    false,
          hidden:      true
        },
        PostTXVPressure1:                 {
          type:        'number',
          description: 'Post TXV Pressure 1',
          required:    false,
          hidden:      true
        },
        PostTXVTemp1:                     {
          type:        'number',
          description: 'Post TXV Temp 1',
          required:    false,
          hidden:      true
        },
        PostTotalSystemWattsTU1:          {
          type:        'number',
          description: 'Post Total System Watts TU 1',
          required:    false,
          hidden:      true
        },
        PostmarkDate:                     {
          type:        'date',
          description: 'Post Mark Date',
          required:    false,
          hidden:      false
        },
        PreBTUTU1Equipment:               {
          type:        'number',
          description: 'Pre BTU TU 1 Equipment',
          required:    false,
          hidden:      true
        },
        PreBlowMotorAmpsTU1:              {
          type:        'number',
          description: 'Pre Blow Motor Amps TU 1',
          required:    false,
          hidden:      true
        },
        PreBlowMotorVoltsTU1:             {
          type:        'number',
          description: 'Pre Blow Motor Volts TU 1',
          required:    false,
          hidden:      true
        },
        PreBlowMotorWattsTU1:             {
          type:        'number',
          description: 'Pre Blow Motor Watts TU 1',
          required:    false,
          hidden:      true
        },
        PreCFM:                           {
          type:        'number',
          description: 'Pre CFM',
          required:    false,
          hidden:      true
        },
        PreCalculatedCFMTU1:              {
          type:        'number',
          description: 'Pre Calculated CFM TU 1',
          required:    false,
          hidden:      true
        },
        PreCoilEnterDBTU1:                {
          type:        'number',
          description: 'Pre Coil Enter DB TU 1',
          required:    false,
          hidden:      true
        },
        PreCoilEnterWBTU1:                {
          type:        'number',
          description: 'Pre Coil Enter WB TU 1',
          required:    false,
          hidden:      true
        },
        PreCoilLeaveDBTU1:                {
          type:        'number',
          description: 'Pre Coil Leave DB TU 1',
          required:    false,
          hidden:      true
        },
        PreCoilLeaveWBTU1:                {
          type:        'number',
          description: 'Pre Coil Leave WB TU 1',
          required:    false,
          hidden:      true
        },
        PreCompressorAmpsTU1:             {
          type:        'number',
          description: 'Pre Compressor Amps TU 1',
          required:    false,
          hidden:      true
        },
        PreCompressorVoltsTU1:            {
          type:        'number',
          description: 'Pre Compressor Volts TU 1',
          required:    false,
          hidden:      true
        },
        PreCompressorWattsTU1:            {
          type:        'number',
          description: 'Pre Compressor Watts TU 1',
          required:    false,
          hidden:      true
        },
        PreCondenserFanAmpsTU1:           {
          type:        'number',
          description: 'Pre Condenser Fan Amps TU 1',
          required:    false,
          hidden:      true
        },
        PreCondenserFanVoltsTU1:          {
          type:        'number',
          description: 'Pre Condenser Fan Volts TU 1',
          required:    false,
          hidden:      true
        },
        PreCondenserFanWattsTU1:          {
          type:        'number',
          description: 'Pre Condenser Fan Watts TU 1',
          required:    false,
          hidden:      true
        },
        PreEffectiveEfficiencyTU1:        {
          type:        'number',
          description: 'Pre Effective Efficiency TU 1',
          required:    false,
          hidden:      true
        },
        PreEnthalpyA:                     {
          type:        'number',
          description: 'Pre Enthalpy A',
          required:    false,
          hidden:      true
        },
        PreEnthalpyB:                     {
          type:        'number',
          description: 'Pre Enthalpy B',
          required:    false,
          hidden:      true
        },
        PreEnthalpyDiffTU1:               {
          type:        'number',
          description: 'Pre Enthalpy Diff TU 1',
          required:    false,
          hidden:      true
        },
        PreIndoorCoilEnteringConditions:  {
          type:        'number',
          description: 'Pre Indoor Coil Entering Conditions',
          required:    false,
          hidden:      true
        },
        PreIndoorCoilLeavingConditions:   {
          type:        'number',
          description: 'Pre Indoor Coil Leaving Conditions',
          required:    false,
          hidden:      true
        },
        PreNominalCapacityTU1:            {
          type:        'number',
          description: 'Pre Nominal Capacity TU 1',
          required:    false,
          hidden:      true
        },
        PreTotalSystemWattsTU1:           {
          type:        'number',
          description: 'Pre Total System Watts TU 1',
          required:    false,
          hidden:      true
        },
        RThs:                             {
          type:        'number',
          description: 'RThs',
          required:    false,
          hidden:      false
        },
        RTls:                             {
          type:        'number',
          description: 'RTls',
          required:    false,
          hidden:      false
        },
        RTss:                             {
          type:        'number',
          description: 'RTss',
          required:    false,
          hidden:      false
        },
        RefrigerationTemp:                {
          type:        'number',
          description: 'Refrigeration Temperature',
          required:    false,
          hidden:      true
        },
        RefrigeratorBasecaseManufacturer: {
          type:        'text',
          description: 'Refrigerator Basecase Manufacturer',
          required:    false,
          hidden:      true
        },
        RefrigeratorBasecaseModel:        {
          type:        'text',
          description: 'Refrigerator Basecase Model #',
          required:    false,
          hidden:      true
        },
        RefrigeratorBasecaseSize:         {
          type:        'text',
          description: 'Refrigerator Basecase Size',
          required:    false,
          hidden:      true
        },
        RefrigeratorBasecaseYear:         {
          type:        'number',
          description: 'Refrigerator Basecase Year',
          required:    false,
          hidden:      true
        },
        RefrigeratorNewEquipmentModel:    {
          type:        'text',
          description: 'Refrigerator New Equipment Model #',
          required:    false,
          hidden:      true
        },
        RefrigeratorNewEquipmentSerial:   {
          type:        'text',
          description: 'Refrigerator New Eqiupment Serial',
          required:    false,
          hidden:      true
        },
        RefrigeratorNewEquipmentSize:     {
          type:        'text',
          description: 'Refrigerator New Equipment Size',
          required:    false,
          hidden:      true
        },
        SHGCbase:                         {
          type:        'number',
          description: 'SHGCbase',
          required:    false,
          hidden:      false
        },
        SHGCeff:                          {
          type:        'number',
          description: 'SHGCeff',
          required:    false,
          hidden:      false
        },
        Type:                             {type: 'text', description: 'Type', required: false, hidden: false},
        Ubase:                            {
          type:        'number',
          description: 'Ubase',
          required:    false,
          hidden:      false
        },
        Ueff:                             {
          type:        'number',
          description: 'Ueff',
          required:    false,
          hidden:      false
        },
        Wattage:                          {
          type:        'number',
          description: 'Baseline Wattage',
          required:    false,
          hidden:      false
        },
        YearBuilt:                        {
          type:        'number',
          description: 'YearBuilt',
          required:    false,
          hidden:      false
        }
      }
    }
  },
  not_reported: {
    description: 'Not Reported',
    attributes:  {
      AdjustedAirFlow:                 {
        type:        'text',
        description: 'Adjusted Air Flow',
        required:    false,
        hidden:      true
      },
      AppliancePrimaryOrSecondaryUnit: {
        type:        'text',
        description: 'Appliance Primary or Secondary Unit',
        required:    false,
        hidden:      true
      },
      ApplianceUnitAmps:               {
        type:        'number',
        description: 'Appliance Unit Amps',
        required:    false,
        hidden:      true
      },
      ApplianceUnitBrand:              {
        type:        'text',
        description: 'Appliance Unit Brand',
        required:    false,
        hidden:      true
      },
      ApplianceUnitCFC11Removal:       {
        type:        'text',
        description: 'Appliance Unit CFC 11 Removal',
        required:    false,
        hidden:      true
      },
      ApplianceUnitDefrostType:        {
        type:        'text',
        description: 'Appliance Unit Defrost Type',
        required:    false,
        hidden:      true
      },
      ApplianceUnitModel:              {
        type:        'text',
        description: 'Appliance Unit Model',
        required:    false,
        hidden:      true
      },
      ApplianceUnitSequenceNumber:     {
        type:        'number',
        description: 'Appliance Unit Sequence Number',
        required:    false,
        hidden:      true
      },
      ApplianceUnitSerialNumber:       {
        type:        'text',
        description: 'Appliance Unit Serial Number',
        required:    false,
        hidden:      true
      },
      AreaMonitored:                   {
        type:        'number',
        description: 'Area Monitored',
        required:    false,
        hidden:      true
      },

      BaselineAge:                 {
        type:        'number',
        description: 'Baseline Age',
        required:    false,
        hidden:      false
      },
      BoilerBaselineEfficiency:    {
        type:        'number',
        description: 'Boiler Baseline Efficiency',
        required:    false,
        hidden:      true
      },
      BoilerReplacementEfficiency: {
        type:        'number',
        description: 'Boiler Replacement Efficiency',
        required:    false,
        hidden:      true
      },
      BonusReceived:               {
        type:        'text',
        description: 'Did Measure receive a bonus?',
        required:    false,
        hidden:      true
      },
      Brand:                       {type: 'text', description: 'Brand', required: false, hidden: true},

      BuildingType:            {
        type:        'text',
        description: 'Building Type',
        required:    false,
        hidden:      true
      },
      CADRWatt:                {
        type:        'number',
        description: 'CADR Watt',
        required:    false,
        hidden:      true
      },
      Class:                   {type: 'text', description: 'Class', required: false, hidden: true},
      CleanAirDeliveryRateCFM: {
        type:        'number',
        description: 'Clean Air Delivery Rate (CFM)',
        required:    false,
        hidden:      true
      },
      ConditionedSqFt:         {
        type:        'number',
        description: 'Conditioned Square Footage',
        required:    false,
        hidden:      true
      },

      CubicFeet: {
        type:        'number',
        description: 'Cubic Feet',
        required:    false,
        hidden:      false
      },

      EfficientEquipment: {
        type:        'text',
        description: 'Efficient Equipment',
        required:    false,
        hidden:      true
      },
      EquipmentCoilModel: {
        type:        'text',
        description: 'Equipment Coil Model #',
        required:    false,
        hidden:      true
      },

      EquipmentEfficiencyRatingEER: {
        type:        'number',
        description: 'Equipment Efficiency Rating (EER)',
        required:    false,
        hidden:      true
      },

      EquipmentName:                   {
        type:        'text',
        description: 'Equipment Name',
        required:    false,
        hidden:      true
      },
      FuelType:                        {
        type:        'text',
        description: 'Fuel Type',
        required:    false,
        hidden:      true
      },
      FurnaceOrAirHandlerManufacturer: {
        type:        'text',
        description: 'Furnace Or Air Handler Manufacturer',
        required:    false,
        hidden:      false
      },
      FurnaceOrAirHandlerModel:        {
        type:        'text',
        description: 'Furnace Or Air Handler Model #',
        required:    false,
        hidden:      false
      },
      ManufacturerInvNumber:           {
        type:        'text',
        description: 'Manufacturer Inv #',
        required:    false,
        hidden:      true
      },
      MeasureIncentive:                {
        type:        'number',
        description: 'Incentive Amount',
        required:    false,
        hidden:      false
      },
      MfgContributionIfAny:            {
        type:        'number',
        description: 'Mfg Contribution if any',
        required:    false,
        hidden:      true
      },
      NewConstruction:                 {
        type:        'text',
        description: 'New Construction',
        required:    false,
        hidden:      true
      },

      NewWaterPumpHeaterEfficiency: {
        type:        'number',
        description: 'New Water Pump Heater Efficiency (EF)',
        required:    false,
        hidden:      true
      },
      NozzleDiameter:               {
        type:        'text',
        description: 'Nozzle Diameter',
        required:    false,
        hidden:      true
      },


      ParticipationType:                  {
        type:        'text',
        description: 'Participation Type',
        required:    false,
        hidden:      true
      },
      PeoplePerHousehold:                 {
        type:        'number',
        description: 'People Per Household',
        required:    false,
        hidden:      false
      },
      OutdoorTemperature:                 {
        type:        'number',
        description: 'OutDoor Temperature',
        required:    false,
        hidden:      true
      },
      SavingsGrossTherms:                 {
        type:        'number',
        description: 'First year Gross Therms per Ameren TRM',
        required:    false,
        hidden:      false
      },
      SavingsNet1StYearkWhTotal:          {
        type:        'number',
        description: 'First year Net kWh per Submittal Tool',
        required:    false,
        hidden:      true
      },
      SavingsNet1StYearkWTotal:           {
        type:        'number',
        description: 'First year Net kW per Submittal Tool',
        required:    false,
        hidden:      true
      },
      SavingsNetMeasureLife09Yr:          {
        type:        'number',
        description: 'End of life Net kW per Submittal Tool ' +
                     'for measures with a life of 0-9 years',
        required:    false,
        hidden:      false
      },
      SavingsNetMeasureLife1014Yr:        {
        type:        'number',
        description: 'End of life Net kW per Submittal Tool ' +
                     'for measures with a life of 10-14 years',
        required:    false,
        hidden:      false
      },
      SavingsNetMeasureLife15Yr:          {
        type:        'number',
        description: 'End of life Net kW per Submittal Tool for ' +
                     'measures with a life of 15 years or greater',
        required:    false,
        hidden:      false
      },
      SavingsNetTherms:                   {
        type:        'number',
        description: 'First year Net Therms per Submittal Tool',
        required:    false,
        hidden:      true
      },
      SavingsNetkWCooling:                {
        type:        'number',
        description: 'Net kW Savings from Cooling',
        required:    false,
        hidden:      false
      },
      SavingsNetkW:                       {
        type:        'number',
        description: 'Net kW savings',
        required:    false,
        hidden:      false
      },
      SavingsNetkWhCooling:               {
        type:        'number',
        description: 'Net kWh Savings from Cooling',
        required:    false,
        hidden:      false
      },
      SavingsNetkWhHeating:               {
        type:        'number',
        description: 'Net kWh Savings from Heating',
        required:    false,
        hidden:      false
      },
      SavingsNetkWh:                      {
        type:        'number',
        description: 'Net kWh Savings',
        required:    false,
        hidden:      false
      },
      SavingskWhNet:                      {
        type:        'number',
        description: 'Net kWh Savings',
        required:    false,
        hidden:      false
      },
      SchoolKitsKitShippedDate:           {
        type:        'Date',
        description: 'School Kits: Kit Shipped Date',
        required:    false,
        hidden:      true
      },
      SchoolKitsKitShippedDate2:          {
        type:        'Date',
        description: 'School Kits: Kit Shipped Date 2',
        required:    false,
        hidden:      true
      },
      SchoolKitsNEFSchoolIdNumber:        {
        type:        'text',
        description: 'School Kits:NEF School ID Number',
        required:    false,
        hidden:      true
      },
      SchoolKitsNEFTeacherIdNumber:       {
        type:        'text',
        description: 'School Kits:NEF Teacher ID Number',
        required:    false,
        hidden:      true
      },
      SchoolKitsNumberOfKItsShipped:      {
        type:        'number',
        description: 'School Kits:Number of Kits Shipped',
        required:    false,
        hidden:      true
      },
      SchoolKitsNumberOfSurveysReturned:  {
        type:        'number',
        description: 'School Kits:Number of Surveys Returned',
        required:    false,
        hidden:      true
      },
      SchoolKitsOfStudentKits:            {
        type:        'number',
        description: 'School Kits: # of Students/Kits',
        required:    false,
        hidden:      true
      },
      SchoolKitsScheduledDate:            {
        type:        'Date',
        description: 'School Kits: Scheduled Date',
        required:    false,
        hidden:      true
      },
      SchoolKitsSchoolDistrictName:       {
        type:        'text',
        description: 'School Kits: School District Name',
        required:    false,
        hidden:      true
      },
      SchoolKitsSchoolYear:               {
        type:        'number',
        description: 'School Kits: School Year',
        required:    false,
        hidden:      true
      },
      SetbackDegreesCooling:              {
        type:        'number',
        description: 'Setback Degrees Cooling',
        required:    false,
        hidden:      true
      },
      SetbackDegreesHeating:              {
        type:        'number',
        description: 'Setback Degrees Heating',
        required:    false,
        hidden:      true
      },
      PurchasePrice:                      {
        type:        'number',
        description: 'Purchase Price',
        required:    false,
        hidden:      true
      },
      RatedLifetime:                      {
        type:        'number',
        description: 'Rated Lifetime',
        required:    false,
        hidden:      true
      },
      RegularRetailPrice:                 {
        type:        'number',
        description: 'Regular_Retail_Price',
        required:    false,
        hidden:      true
      },
      RemovedEquipIndoorCoilTempEntering: {
        type:        'number',
        description: 'Removed Equipment Indoor Coil Temperature Entering (degrees F)',
        required:    false,
        hidden:      false
      },
      RemovedEquipIndoorCoilTempLeaving:  {
        type:        'number',
        description: 'Removed Equipment Indoor Coil Temperature Leaving (degrees F)',
        required:    false,
        hidden:      true
      },
      RemovedEquipmentEER:                {
        type:        'number',
        description: 'Removed Equipment EER',
        required:    false,
        hidden:      false
      },
      RemovedEquipmentManufacturer:       {
        type:        'text',
        description: 'Removed Equipment Manufacturer',
        required:    false,
        hidden:      true
      },
      RemovedEquipmentModel:              {
        type:        'text',
        description: 'Removed Equipment Model #',
        required:    false,
        hidden:      true
      },
      RemovedEquipmentTons:               {
        type:        'number',
        description: 'Removed Equipment (Tons)',
        required:    false,
        hidden:      false
      },
      ReplacedAirFilter:                  {
        type:        'text',
        description: 'Replaced Air Filter? (TuneUp Meaure Only)',
        required:    false,
        hidden:      true
      },
      ReplacementFurnaceEfficiency:       {
        type:        'number',
        description: 'Replacement Furnace Efficiency',
        required:    false,
        hidden:      true
      },
      ReplacementLampType:                {
        type:        'text',
        description: 'Replacement Lamp Type',
        required:    false,
        hidden:      true
      },
      ReplacementType:                    {
        type:        'text',
        description: 'Replacement Type',
        required:    false,
        hidden:      true
      },
      RetailunAfterIncentive:             {
        type:        'number',
        description: 'Retail Price after Incentive',
        required:    false,
        hidden:      true
      },
      Retailer:                           {
        type:        'text',
        description: 'Retailer',
        required:    false,
        hidden:      true
      },
      RetailerAddress:                    {
        type:        'text',
        description: 'Retailer Address',
        required:    false,
        hidden:      true
      },
      RetailerCity:                       {
        type:        'text',
        description: 'Retailer City',
        required:    false,
        hidden:      true
      },
      RetailerPointOfSaleInformation:     {
        type:        'text',
        description: 'Retailer Point of Sale Information',
        required:    false,
        hidden:      true
      },
      RetailerZip:                        {
        type:        'text',
        description: 'Retailer Zip',
        required:    false,
        hidden:      true
      },
      SKU:                                {type: 'text', description: 'SKU', required: false, hidden: true},
      Standby:                            {
        type:        'text',
        description: 'Standby',
        required:    false,
        hidden:      true
      },
      TermsandConditions:                 {
        type:        'text',
        description: 'Terms and Conditions',
        required:    false,
        hidden:      true
      },
      UnitType:                           {
        type:        'text',
        description: 'Unit Type',
        required:    false,
        hidden:      true
      }
    }
  }
};
