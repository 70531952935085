import React, {forwardRef, memo, useCallback, useEffect, useReducer, useRef, useState} from "react";
import {useHistory} from 'react-router-dom';
import {useGet, useMutate} from "restful-react";
import material_table_icons from "../../../common/material_table_icons";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AddBox from "@material-ui/icons/AddBox";
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import ApartmentIcon from '@material-ui/icons/ApartmentOutlined';
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import GetAppIcon from '@material-ui/icons/GetAppOutlined';
import Edit from "@material-ui/icons/EditOutlined";
import SaveAltOutline from "@material-ui/icons/SaveAltOutlined";
import WbIncandescentIcon from '@material-ui/icons/WbIncandescentOutlined';
import ObjectTable from "./ObjectTable";
import {useSnackbar} from "notistack";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MeasureTable from "./MeasureTable";
import material_table_query from "../../../common/material_table_query";
import AddressLookup from "./AddressLookup";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CSVPicker from "../../../components/CSVPicker";
import {CsvBuilder} from "filefy";
import MapView from './MapView';

const useStyles = makeStyles((theme) => {
  return {
    root:                      {
      [theme.breakpoints.up('md')]: {
        height: '100%'
      }
    },
    detailPaper:               {
      padding: theme.spacing(2)
    },
    detailPanelMeasuresSpacer: {
      paddingBottom:   theme.spacing(3),
      backgroundColor: theme.palette.background.default
    },
    dialogTitle:               {
      backgroundColor: theme.palette.primary.main,
      color:           theme.palette.primary.contrastText
    },
    dialogActions:             {
      backgroundColor: theme.palette.background.default
    },
    dropZoneAreaError:         {
      borderColor: theme.palette.error.main
    }
  };
});

const BulkUnitUpload = ({project, open, set_open}) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [do_lookup, set_do_lookup] = useState(false);
  const {
    mutate: bulk_create_units,
    error:  bulk_create_unit_error
  } = useMutate('POST', `/data/projects/${project.id}/units/bulk`);
  useEffect(() => {
    if (bulk_create_unit_error) {
      enqueueSnackbar('Could not schedule unit lookup', {variant: 'error'});
    }
  }, [bulk_create_unit_error, enqueueSnackbar]);


  return (
    <Dialog open={open} onClose={() => set_open(false)}>
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Typography component="h2" variant="h4" color="inherit">
          Bulk Unit Lookup
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.layout} dividers={true}>
        <CSVPicker
          mapped_fields={[
            'account_number',
            'premise_number',
            'customer_name',
            'house_number',
            'street_name',
            'city',
            'state',
            'postal_code'
          ]}
          required_fields={[]}
          do_submit={do_lookup}
          submit_done={() => set_do_lookup(false)}
          set_extracted_data={async (data) => {
            try {
              set_do_lookup(false);
              await bulk_create_units(data);
              set_open(false);
              enqueueSnackbar('Unit lookup scheduled', {variant: "success"});
            } catch (e) {
              //error submitting, handled in effect
            }
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => set_open(false)}
                className={classes.button}>
          Cancel
        </Button>
        <Button color="primary"
                onClick={() => set_do_lookup(true)}
                className={classes.button}>
          Create Units
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UnitDetails = forwardRef(({project, unit_data}, ref) => {
  const {enqueueSnackbar} = useSnackbar();
  const {
    mutate: update_unit,
    error
  } = useMutate('PUT', `/data/projects/${project.id}/units/${unit_data.id}`);
  useEffect(() => {
    if (error) {
      enqueueSnackbar('Could not update unit', {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);
  const classes = useStyles();
  const [name, set_name] = useState(unit_data.name);
  const name_error = !name || name === '';
  const update_click = async () => {
    await update_unit({unit: {name}});
    ref.current.onQueryChange();
    enqueueSnackbar('Unit updated', {variant: "success"});
  };

  return (
    <Paper className={classes.detailPaper} elevation={0}>
      <Grid container>
        <Grid item xs={11}>
          <TextField value={name}
                     error={name_error}
                     helperText={name_error ? 'Name is required' : null}
                     onChange={(event) => set_name(event.target.value)}
                     required
                     margin='dense'
                     name="unit_name"
                     label="Unit Name"
                     fullWidth
                     autoComplete="off"/>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={update_click}>
            <SaveAltOutline/>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Input
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ObjectTable row={unit_data.input}/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Unit Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container container direction="row" justify="space-evenly" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <ObjectTable row={unit_data.response}/>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <MapView address={unit_data.response.BillingStreet +(+unit_data.response.BillingStreet2?' ':'')+unit_data.response.BillingStreet2 +', '+unit_data.response.BillingCity+', '+unit_data.response.BillingState+' '+unit_data.response.BillingPostalCode}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});

const UnitTable = memo(forwardRef(
  ({
     project,
     set_open_new_unit,
     set_open_bulk_unit
   }, ref) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const {
      mutate: delete_unit,
      error:  delete_error
    } = useMutate('DELETE', `/data/projects/${project.id}/units`);
    useEffect(() => {
      if (delete_error) {
        enqueueSnackbar('Could not delete unit', {variant: 'error'});
      }
    }, [delete_error, enqueueSnackbar]);
    return (
      <MaterialTable
        title={
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item>
              <ApartmentIcon/>
            </Grid>
            <Grid item>
              Units
            </Grid>
          </Grid>
        }
        tableRef={ref}
        icons={{
          ...material_table_icons,
          Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color='action'/>),
          Edit:   forwardRef((props, ref) => <Edit {...props} ref={ref} color='action'/>)
        }}
        components={{
          Container: ({children}) => <Paper className={classes.root} elevation={1}>{children}</Paper>
        }}
        columns={[
          {
            title:    'ID',
            field:    'id',
            type:     "numeric",
            editable: 'never'
          },
          {
            title: 'Name',
            field: 'name'
          },
          {
            title: 'Account Number',
            field: 'electric_account_number'
          },
          {
            title: 'Street',
            field: 'account_street'
          },
          {
            title: 'Street 2',
            field: 'account_street2'
          },
          {
            title: 'City',
            field: 'account_city'
          },
          {
            title: 'State',
            field: 'account_state'
          },
          {
            title: 'Postal Code',
            field: 'account_postal_code'
          }
        ]}
        data={material_table_query({
                                     url:      `/api/data/projects/${project.id}/units`,
                                     data_key: 'units',
                                     on_error: () => history.push('/')
                                   })}
        actions={[
          {
            icon:         () => <AddBox color='primary'/>,
            tooltip:      'Add Unit',
            isFreeAction: true,
            onClick:      () => set_open_new_unit(true)
          },
          {
            icon:         () => (<AddToPhotosOutlinedIcon color='action'/>),
            tooltip:      'Bulk Unit Lookup',
            isFreeAction: true,
            onClick:      () => set_open_bulk_unit(true)
          },
          {
            icon:    () => <DeleteOutline color='action'/>,
            tooltip: 'Delete Units',
            onClick: async (event, rows) => {
              for (const row of rows) {
                await delete_unit(row.id);
              }
              enqueueSnackbar('Units deleted', {variant: "success"});
              ref.current.onQueryChange();
            }
          },
          {
            icon: () => <GetAppIcon color="action"/>,
            tooltip: 'Export All Units',
            isFreeAction: true,
            onClick: async () => {
              try {
                const res = await fetch(`/api/data/projects/${project.id}/units`);
                const {units} = await res.json();
                console.log(units);
                const csv_builder = new CsvBuilder(`project_${project.id}_units.csv`);
                csv_builder
                  .setColumns([
                                'Name',
                                'Account Number',
                                'Street',
                                'Premise ID',
                                'Account Type',
                                'Is Active'
                              ])
                  .addRows(units.map((row) => {
                    return [
                      row.name,
                      row.electric_account_number,
                      row.account_street,
                      row.response.ElectricPremiseNumber,
                      row.response.AccountType,
                      row.response.IsActive
                    ];
                  }))
                  .exportFile();
                enqueueSnackbar('Exported all units', {variant: 'success'});
              } catch (e) {
                console.log(e);
                enqueueSnackbar('Cannot export all units', {variant: 'error'});
              }
            }
          }
        ]}
        editable={{
          onRowAdd:    undefined,
          onRowDelete: async (row) => {
            await delete_unit(row.id);
            enqueueSnackbar('Unit deleted', {variant: "success"});
            ref.current.onQueryChange();
          },
          onRowUpdate: async (new_row, old_row) => {
            let unit = {...new_row};
            delete unit.id;
            delete unit.input;
            delete unit.response;
            Object.keys(unit).forEach((property) => {
              if (new_row[property] === old_row[property]) {
                delete unit[property];
              }
            });
            await fetch(`/api/data/projects/${project.id}/units/${new_row.id}`,
                        {
                          method:  'PUT',
                          body:    JSON.stringify({unit}),
                          headers: {
                            'Content-Type': 'application/json'
                          }
                        });
            enqueueSnackbar('Unit updated', {variant: "success"});
            ref.current.onQueryChange();
            return true;
          }
        }}
        options={{
          actionsColumnIndex:  -1,
          search:              false,
          emptyRowsWhenPaging: false,
          pageSize:            25,
          pageSizeOptions:     [25, 50, 100],
          selection:           true
        }}
        detailPanel={
          [
            {
              tooltip: 'Unit Details',
              render:  (row) => <UnitDetails project={project} unit_data={row} ref={ref}/>
            },
            {
              tooltip:  'Measures',
              icon:     () => <WbIncandescentIcon color='action'/>,
              openIcon: () => <WbIncandescentIcon color='secondary'/>,
              render:   (row) => {
                return (
                  <Paper className={classes.detailPaper} elevation={0}>
                    <MeasureTable elevation={0}
                                  measures_url={`/data/projects/${project.id}/units/${row.id}/measures`}/>
                  </Paper>
                );
              }
            }
          ]
        }
      />
    );
  }
));

const key_reducer = (value) => {
  return ++value;
};

export default ({project}) => {
  const table_ref = useRef();
  const [new_unit_key, increment_new_unit_key] = useReducer(key_reducer, 0);
  const [open_new_unit, set_open_new_unit_] = useState(false);
  const set_open_new_unit = useCallback((val) => {
    if (!val) {
      increment_new_unit_key();
    }
    set_open_new_unit_(val);
  }, []);
  const [bulk_unit_key, increment_bulk_unit_key] = useReducer(key_reducer, 0);
  const [open_bulk_unit, set_open_bulk_unit_] = useState(false);
  const set_open_bulk_unit = useCallback((val) => {
    if (!val) {
      increment_bulk_unit_key();
    }
    set_open_bulk_unit_(val);
  }, []);

  return (
    <>
      <UnitTable project={project}
                 set_open_new_unit={set_open_new_unit}
                 set_open_bulk_unit={set_open_bulk_unit}
                 ref={table_ref}/>
      <AddressLookup key={`AddressLookup.${new_unit_key}`}
                     project={project}
                     open={open_new_unit}
                     set_open={set_open_new_unit}
                     ref={table_ref}/>
      <BulkUnitUpload key={`BulkUnitUpload.${bulk_unit_key}`}
                      project={project}
                      open={open_bulk_unit}
                      set_open={set_open_bulk_unit}/>
    </>
  );
};
