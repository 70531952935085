/**
 * @callback RemovalPredicate
 * @param {*} Object property
 * @return {boolean} True if property should be removed
 */

/**
 * @callback PropertyRemover
 * @param {object} Object from which to remove properties
 */

/**
 *
 * @param {RemovalPredicate} predicate
 * @returns {PropertyRemover} Function to process an object for removal
 */
const remove_properties_if = (predicate) => {
  /**
   * Removes properties from an object accorting
   * to a predicate
   * @param {object} obj object to process
   */
  const f = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        f(obj[key]);
      } else if (predicate(obj[key])) {
        delete obj[key];
      }
    });
  };
  return f;
};

const remove_properties_if_falsy = remove_properties_if((property) => !property);
const remove_properties_if_empty_string = remove_properties_if((property) => property === '');

export {
  remove_properties_if,
  remove_properties_if_falsy,
  remove_properties_if_empty_string
};
