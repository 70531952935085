import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root:  {
    padding: theme.spacing(4)
  },
  media: {
    //height: '100%',
  },
  text:  {
    marginTop: theme.spacing(4)
  }
}));

export default () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        className={classes.media}
        image="/icast_logo_full.png"
        title="Logo"
      />
      <Typography
        className={classes.text}
        gutterBottom
        align="center"
        color="primary"
        variant="h2">
        Ameren Portal
      </Typography>
    </Card>
  );
};
