import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';

import {useMutate} from "restful-react";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import material_table_icons from "../../common/material_table_icons";
import material_table_query from "../../common/material_table_query";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import PeopleIcon from "@material-ui/icons/People";
import ToggleIcon from "material-ui-toggle-icon";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";

const useStyles = makeStyles(theme => ({
  root:    {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

export default () => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  let history = useHistory();
  let table_ref = useRef();
  const [show_hidden, set_show_hidden] = useState(false);

  const {
    mutate: show_hide_user,
    error:  show_hide_error
  } = useMutate('PUT', `/data/users`);
  useEffect(() => {
    if (show_hide_error) {
      enqueueSnackbar('Cannot set user visibility', {variant: 'error'});
    }
  }, [show_hide_error, enqueueSnackbar]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <MaterialTable
          title={
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item>
                <PeopleIcon/>
              </Grid>
              <Grid item>
                Users
              </Grid>
            </Grid>
          }
          tableRef={table_ref}
          icons={material_table_icons}
          columns={[
            {
              title:       'ID',
              field:       'id',
              type:        "numeric",
              headerStyle: {
                width: '100px'
              },
              cellStyle:   {
                width: '100px'
              }
            },
            {
              title:  'EMail',
              field:  'email',
              render: (row) => (<Link to={`/users/${row.id}`}>{row.email}</Link>)
            },
            {
              title: 'First Name',
              field: 'first_name'
            }, {
              title: 'Last Name',
              field: 'last_name'
            },
            {
              title: 'Title',
              field: 'title'
            },
            {
              title:   'Roles',
              field:   'roles',
              render:  (row) => row.roles.map((role) => role.name).join(', '),
              sorting: false
            },
            {
              title:  'Hidden',
              field:  'hidden',
              hidden: true
            }
          ]}
          data={material_table_query({
                                       url:          '/api/data/users',
                                       data_key:     'users',
                                       on_error:     () => history.push('/'),
                                       query_params: {show_hidden}
                                     })}
          actions={[
            {
              icon:         () => <ToggleIcon on={show_hidden}
                                              offIcon={<VisibilityIcon color='action'/>}
                                              onIcon={<VisibilityOffIcon color='action'/>}/>,
              tooltip:      show_hidden ? 'Hide Hidden Users' : 'Show Hidden Users',
              isFreeAction: true,
              onClick:      (event) => {
                set_show_hidden(!show_hidden);
                table_ref.current.onQueryChange();
              }
            },
            {
              icon:         () => <AddBox color='primary'/>,
              tooltip:      'Add User',
              isFreeAction: true,
              onClick:      (event) => history.push('/users/new')
            },
            (row) => ({
              icon:    () => <ToggleIcon on={!row.hidden}
                                         offIcon={<VisibilityIcon color='action'/>}
                                         onIcon={<VisibilityOffIcon color='action'/>}/>,
              tooltip: row.hidden ? 'Show User' : 'Hide User',
              onClick: async (event, row) => {
                await show_hide_user({},
                                     {
                                       queryParams: {
                                         action: 'toggle_visibility',
                                         userId: row.id
                                       }
                                     });
                enqueueSnackbar('User visibility changed', {variant: 'success'});
                table_ref.current.onQueryChange();
              }
            })
          ]}
          options={{
            actionsColumnIndex:  -1,
            search:              false,
            emptyRowsWhenPaging: false,
            pageSize:            10,
            pageSizeOptions:     [10, 25, 50]
          }}
        />

      </div>
    </div>
  );
};
