import React from "react";
import {hasRole, useUser} from "../context/user_context";
import {Redirect, Switch, useParams} from "react-router-dom";
import {RouteWithLayout} from "./index";
import {Main as MainLayout} from "../layouts";

const Resource = (props) => {
  const {
    IndividualComponent,
    ListComponent,
    match: {path}
  } = props;
  const user = useUser();
  return (
    <Switch>
      {hasRole(user, 'user') &&
       <>
         <RouteWithLayout
           path={`${path}/:id(\\d+|new)`}
           layout={MainLayout}
           component={IndividualComponent}
         />
         <RouteWithLayout
           exact path={path}
           layout={MainLayout}
           component={ListComponent}
         />
       </>}
      <Redirect to="/not-found"/>
    </Switch>
  );
};

const useResourceParams = () => {
  const params = useParams();
  const is_new = params.id === 'new';
  const id = is_new ? -1 : parseInt(params.id);
  return {id, is_new};
};

const withResourceShell = (Component) => {
  return (props) => {
    return <Component key={props.match.params.id} {...props} />;
  };
};

export {Resource as default, useResourceParams, withResourceShell};
