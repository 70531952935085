import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {makeStyles} from '@material-ui/styles';
import clsx from "clsx";
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {useAuth} from "../../context/auth_context";
import {useSnackbar} from "notistack";

const schema = {
  email:    {
    presence: {allowEmpty: false, message: 'is required'},
    email:    true
    //length: { maximum: 64 }
  },
  password: {
    presence: {allowEmpty: false, message: 'is required'}
    //length: { maximum: 128 }
  }
};

const useStyles = makeStyles(theme => ({
  root:             {
    backgroundColor:              theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      height: '100vh'
    },
    height:                       "100%"
  },
  grid:             {
    height: '100%'
  },
  name:             {
    marginTop: theme.spacing(3),
    color:     theme.palette.white
  },
  bio:              {
    color: theme.palette.white
  },
  centered:         {
    margin: 'auto'
  },
  contentContainer: {},
  content:          {
    height:        '100%',
    display:       'flex',
    flexDirection: 'column',
    paddingTop:    theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  contentHeader:    {
    display:       'flex',
    alignItems:    'center',
    paddingTop:    theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft:   theme.spacing(2),
    paddingRight:  theme.spacing(2)
  },
  contentBody:      {
    flexGrow:                       1,
    display:                        'flex',
    alignItems:                     'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  logoImage:        {
    //marginLeft: theme.spacing(4)
    margin:                         'auto',
    [theme.breakpoints.down('md')]: {
      height: '25vh'
    }
  },
  formHeader:       {
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.primary.contrastText,
    textAlign:       'center'
  },
  form:             {
    padding: theme.spacing(4)
  },
  title:            {
    marginTop: theme.spacing(3)
  },
  textField:        {
    marginTop: theme.spacing(2)
  },
  signInButton:     {
    margin: theme.spacing(2, 0)
  }
}));

const Login = () => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {
    login,
    errors: {
              login: login_error = null
            }
  } = useAuth();

  const [formState, setFormState] = useState({
                                               isValid: false,
                                               values:  {},
                                               touched: {},
                                               errors:  {}
                                             });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors:  errors || {}
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values:  {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    login(formState.values.email, formState.values.password);
  };

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);

  useEffect(() => {
    if (login_error) {
      enqueueSnackbar('Invalid Username Or Password', {variant: 'error'});
    }
  }, [login_error, enqueueSnackbar]);

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={5}
          xs={12}
        >
          <img
            className={classes.logoImage}
            src={'/icast_logo_full.png'}
            alt={'Logo'}
          />
        </Grid>
        <Grid
          className={clsx(classes.content)}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <Card className={classes.centered}>
                <CardHeader
                  className={classes.formHeader}
                  title={'Sign In'}
                  titleTypographyProps={{variant: "h2", className: classes.formHeader}}
                />
                <CardContent>
                  <form
                    className={classes.form}
                    onSubmit={handleSignIn}
                    autoComplete={"true"}
                  >
                    <TextField
                      className={classes.textField}
                      error={!!login_error || hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      label="Email address"
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={!!login_error || hasError('password')}
                      fullWidth
                      helperText={
                        hasError('password') ? formState.errors.password[0] : null
                      }
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.password || ''}
                      variant="outlined"
                    />
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign In
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object
};

export default withRouter(Login);
