import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {
  RouteWithLayout,
  Resource
} from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout
} from './layouts';
import {
  useUser,
  hasRole
} from './context/user_context';

import {
  Dashboard as DashboardView,
  Project as ProjectView,
  ProjectList as ProjectListView,
  Contractor as ContractorView,
  ContractorList as ContractorListView,
  User as UserView,
  UserList as UserListView,
  Login as LoginView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  const user = useUser();
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={user ? "/dashboard" : "/login"}
      />
      {user && <Redirect exact
                         from="/login"
                         to="/dashboard"
      />
      }
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      {!user && <Redirect to={"/login"}/>}
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      {hasRole(user, 'user') &&
       <Redirect
         exact
         from={'/user'}
         to={`/users/${user.id}`}
       />
      }
      <Route path={"/users"}
             render={({match}) => {
               return (
                 <Resource
                   match={match}
                   IndividualComponent={UserView}
                   ListComponent={UserListView}
                 />
               );
             }}
      />
      <Route path={"/projects"}
             render={({match}) => {
               return (
                 <Resource
                   match={match}
                   IndividualComponent={ProjectView}
                   ListComponent={ProjectListView}
                 />
               );
             }}
      />
      <Route path={"/contractors"}
             render={({match}) => {
               return (
                 <Resource
                   match={match}
                   IndividualComponent={ContractorView}
                   ListComponent={ContractorListView}
                 />
               );
             }}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found"/>
    </Switch>
  );
};

export default Routes;
