import React from "react";
import Typography from "@material-ui/core/Typography";
import ObjectTable from "./ObjectTable";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    row: {
      paddingTop:            theme.spacing(2),
      paddingBottom:         theme.spacing(2),
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.default
      }
    }
  };
});

export default ({input, selected, set_selected}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Input
      </Typography>
      <ObjectTable row={input}/>
      <Typography variant="h4" gutterBottom>
        Selected
      </Typography>
      <List>
        {
          Object.keys(selected).map((s) => {
            const name_error = !selected[s].name || selected[s].name === '';
            return (
              <ListItem key={`li${selected[s].response.ElectricAccountNumber}`}
                        className={classes.row}
                        divider={true}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField value={selected[s].name}
                               error={name_error}
                               helperText={name_error ? 'Name is required' : null}
                               onChange={(event) => {
                                 let selected_ = Object.assign({}, selected);
                                 selected_[s].name = event.target.value;
                                 set_selected(selected_);
                               }}
                               required
                               margin='dense'
                               name="unit_name"
                               label="Unit Name"
                               fullWidth
                               autoComplete="off"/>
                  </Grid>
                  <Grid item xs={12}>
                    <ObjectTable row={selected[s].response}/>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })
        }
      </List>
    </>
  );
};
