import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import ZoomOutMapOutlinedIcon from '@material-ui/icons/ZoomOutMapOutlined';
import {LoadingPaper, useResourceParams, withResourceShell} from "../../components";
import {useGet} from "restful-react";
import {useSnackbar} from "notistack";
import {useHistory} from 'react-router-dom';
import ProjectDetails from "./components/ProjectDetails";
import MeasureSummary from "./components/MeasureSummary";
import UnitList from "./components/UnitList";
import MeasureTable from "./components/MeasureTable";
import WorkorderDetails from "./components/WorkorderDetails";
import MeasureDistributor from "./components/MeasureDistributor";

const useStyles = makeStyles(theme => ({
  root:          {
    padding: theme.spacing(4)
  },
  content:       {
    marginTop: theme.spacing(2)
  },
  toolbarButton: {
    marginLeft: theme.spacing(2)
  },
  layout:        {
    width:                                              'auto',
    marginLeft:                                         theme.spacing(2),
    marginRight:                                        theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      //width: 600,
      marginLeft:  'auto',
      marginRight: 'auto'
    }
  },
  button:        {
    margin: theme.spacing(1)
  },

}));

const Project = () => {
  const classes = useStyles();
  const resource_params = useResourceParams();
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const [distribute_key, set_distribute_key] = useState(1);
  const [open_distribute, set_open_distribute] = useState(false);
  const {id, is_new} = resource_params;
  const {
    data,
    loading,
    refetch,
    error
  } = useGet({path: `/data/projects/${id}`, lazy: true});
  useEffect(() => {
    if (!is_new && !loading && !data) {
      refetch().then();
    }
  }, [is_new, loading, data, refetch]);
  useEffect(() => {
    if (error) {
      if (error.status && error.status === 403) {
        enqueueSnackbar('Permission denied', {variant: 'error'});
      } else {
        enqueueSnackbar('Cannot load project', {variant: 'error'});
      }
      history.push('/projects');
    }
  }, /* eslint-disable react-hooks/exhaustive-deps */ [error, enqueueSnackbar]);

  const close_distribute = useCallback(() => {
    set_distribute_key((key) => key + 1);
    set_open_distribute(false);
  }, [set_open_distribute, set_distribute_key]);

  const project = is_new ? {} : data ? data.project : {id};


  return !is_new && (!data || loading) ?
         <LoadingPaper style={{height: '50vh'}}/>
                                       :
         (
           <div className={classes.root}>
             <div className={classes.content}>
               <Grid container spacing={3} justify={'center'}>
                 <Grid item xs={12} md={is_new ? 6 : 5}>
                   <ProjectDetails
                     {...resource_params}
                     project={project}
                   />
                 </Grid>
                 {
                   !is_new &&
                   <>
                     <Grid item xs={12} md={7}>
                       <WorkorderDetails project={project} reload_project={refetch}/>
                     </Grid>
                     <Grid item xs={12}>
                       <MeasureSummary project={project}/>
                     </Grid>
                     <Grid item xs={12}>
                       <UnitList project={project}/>
                     </Grid>
                     <Grid item xs={12}>
                       <MeasureTable title='Measures Added To All Units'
                                     measures_url={`/data/projects/${project.id}/measures`}
                                     actions={[
                                       {
                                         icon:         () => <ZoomOutMapOutlinedIcon color='action'/>,
                                         tooltip:      'Distribute Measures',
                                         isFreeAction: true,
                                         onClick:      () => set_open_distribute(true)
                                       }
                                     ]}/>
                     </Grid>
                   </>
                 }
               </Grid>
             </div>
             <MeasureDistributor key={distribute_key}
                                 project={project}
                                 refetch_project={refetch}
                                 open_distribute={open_distribute}
                                 close_distribute={close_distribute}/>
           </div>
         );
};

export default withResourceShell(Project);
