import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CSVPicker from "../../../components/CSVPicker";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {useMutate} from "restful-react";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  root:          {
    padding: theme.spacing(4)
  },
  content:       {
    marginTop: theme.spacing(2)
  },
  toolbarButton: {
    marginLeft: theme.spacing(2)
  },
  layout:        {
    width:                                              'auto',
    marginLeft:                                         theme.spacing(2),
    marginRight:                                        theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      //width: 600,
      marginLeft:  'auto',
      marginRight: 'auto'
    }
  },
  button:        {
    margin: theme.spacing(1)
  },
  dialogTitle:   {
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.primary.contrastText
  },
  dialogActions: {
    backgroundColor: theme.palette.background.default
  },
  errorDivider:  {
    marginTop: theme.spacing(2)
  }
}));

const MeasureDistributor = ({
                              project,
                              refetch_project,
                              open_distribute,
                              close_distribute
                            }) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [do_distribute, set_do_distribute] = useState(false);
  const {
    mutate: distribute_measures,
    error:  distribute_measures_error
  } = useMutate('POST', `/data/projects/${project.id}/distribute_measures`);
  useEffect(() => {
    if (distribute_measures_error) {
      enqueueSnackbar((<>

                      </>),
                      {variant: 'error'});
    }
  }, [distribute_measures_error, enqueueSnackbar]);

  return (
    <Dialog open={open_distribute} onClose={close_distribute}>
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Typography component="h2" variant="h4" color="inherit">
          Distribute Measures To Units
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.layout} dividers={true}>
        <CSVPicker
          mapped_fields={[
            'electric_account_number',
            'measure_external_id',
            'service_performed',
            'quantity',
            'serial_number',
            'equipment_condenser_serial',
            'outdoor_serial_number',
            'refrigerator_new_equipment_serial',
            'appliance_unit_serial_number'
          ]}
          required_fields={[
            'electric_account_number',
            'measure_external_id',
            'service_performed'
          ]}
          do_submit={do_distribute}
          submit_done={() => set_do_distribute(false)}
          set_extracted_data={async (data) => {
            try {
              set_do_distribute(false);
              await distribute_measures(data);
              close_distribute();
              enqueueSnackbar('Measures distributed', {variant: "success"});
              refetch_project();
            } catch (e) {
              //error submitting, handled in effect
            }
          }}
        />
        <Divider className={classes.errorDivider}/>
        {distribute_measures_error &&
         <Grid container spacing={1}>
           <Grid item xs={12}>
             <Typography variant={'h6'} color={'error'}>
               Errors occurred while distributing measures
             </Typography>
           </Grid>
           <Grid item xs={12}>
             <Table size='small'>
               <TableHead>
                 <TableRow>
                   <TableCell>Electric Account Number</TableCell>
                   <TableCell>Measure External ID</TableCell>
                   <TableCell>Service Performed</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {distribute_measures_error
                   .data.results.filter((result) => (result.value.status !== 200))
                   .map(({value: {adjustment}}) => (
                     <TableRow
                       key={`${adjustment.electric_account_number}.${adjustment.measure_external_id}`}>
                       <TableCell>{adjustment.electric_account_number}</TableCell>
                       <TableCell>{adjustment.measure_external_id}</TableCell>
                       <TableCell>{adjustment.service_performed}</TableCell>
                     </TableRow>
                   ))}
               </TableBody>
             </Table>
           </Grid>
         </Grid>
        }
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={close_distribute}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => set_do_distribute(true)}
          className={classes.button}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeasureDistributor;