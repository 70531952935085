/**
 * Generate data query function for material-table
 * @param {string} url Resource from which to request data
 * @param {string} data_key Data property in returned JSON
 * @param {function(): *} [on_error] Action on error
 * @param {object} [query_params] Extra query params
 * @return {function(*)} Data gathering function
 */
export default ({url, data_key, on_error, query_params}) => (
  async (query) => {
    let qs = new URLSearchParams(query_params);
    qs.append('page', query.page);
    qs.append('page_size', query.pageSize);
    if (query.orderBy) {
      qs.append('order', query.orderBy.field);
      if (query.orderDirection) {
        qs.append('order_direction', query.orderDirection);
      }
    }
    try {
      const res = await fetch(`${url}?${qs.toString()}`);
      const data = await res.json();
      return {
        data:       data[data_key],
        page:       query.page,
        totalCount: data.count
      };
    } catch (e) {
      console.log(e);
      on_error && on_error();
      return {
        data:       [],
        page:       0,
        totalCount: 0
      }
    }
  }
)
