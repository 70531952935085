import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useMutate, useGet} from "restful-react";
import {useSnackbar} from "notistack";
import UserAutocomplete from "./UserAutocomplete";
import titleize from "titleize";
import humanizeString from "humanize-string";
import MenuItem from "@material-ui/core/MenuItem";
import {LoadingPaper} from "../../../components";

const useStyles = makeStyles((theme) => ({
  root:        {
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  cardActions: {
    justifyContent: 'flex-end'
  }
}));


const ProjectDetails = props => {
  const {
    project,
    is_new,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  let history = useHistory();

  const http_op = is_new ? 'POST' : 'PUT';
  const resource = is_new ? '' : `/${project.id}`;

  const {
    mutate,
    error
  } = useMutate(http_op, `/data/projects${resource}`);
  useEffect(() => {
    if (error) {
      enqueueSnackbar('Could not update project', {variant: "error"});
    }
  }, [error, enqueueSnackbar]);

  const {
    data:    contractors,
    error:   contractors_error,
    loading: contractors_loading
  } = useGet('/data/contractors');
  useEffect(() => {
    if (contractors_error) {
      enqueueSnackbar('Could not list contractors', {variant: "error"});
    }
  }, [contractors_error, enqueueSnackbar]);

  let initial_state = {
    ...{
      name:          '',
      invoice:       '',
      contract:      '',
      contractor_id: '',
      owner:         null
    },
    ...project
  };
  if (initial_state.owner && initial_state.owner.email) {
    initial_state.owner = initial_state.owner.email;
  }
  Object.keys(initial_state).forEach((k) => {
    if (initial_state[k] === null && k !== 'owner') {
      initial_state[k] = '';
    }
  });

  const [values, setValues] = useState(initial_state);
  const update_values = (name, value) => {
    setValues({
                ...values,
                [name]: value
              });
  };

  const submit = async (event) => {
    event.preventDefault();
    let submission = Object.assign({}, values);
    Object.keys(submission).forEach((k) => {
      if (submission[k] === '') {
        delete submission[k];
      }
    });

    if (is_new) {
      const new_project = await mutate({project: submission});
      history.push(`/projects/${new_project.project.id}`);
    } else {
      await mutate({project: submission});
      enqueueSnackbar('Project updated', {variant: "success"});
    }
  };

  return (
    (!contractors || contractors_loading) ?
    <LoadingPaper style={{height: '50vh'}}/> :
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form autoComplete="false"
            noValidate
            onSubmit={submit}
      >
        <CardHeader title={is_new ? "New Project" : project.name}
                    subheader="Project Attributes"
        />
        <Divider/>
        <CardContent>
          <Grid container
                spacing={1}
                alignItems={"stretch"}>
            <Grid item xs={12} md={!is_new ? 6 : undefined}>
              <TextField
                fullWidth
                label="Name"
                margin="dense"
                name="name"
                onChange={({target: {name, value}}) => update_values(name, value)}
                required
                value={values.name}
                autoComplete="false"
              />
            </Grid>
            {!is_new &&
             <Grid item xs={12} md={6}>
               <TextField
                 fullWidth
                 label="Status"
                 margin="dense"
                 disabled={true}
                 value={titleize(humanizeString(project.status || ''))}
               />
             </Grid>
            }
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Invoice"
                margin="dense"
                name="invoice"
                onChange={({target: {name, value}}) => update_values(name, value)}
                value={values.invoice}
                autoComplete="false"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Contract"
                margin="dense"
                name="contract"
                onChange={({target: {name, value}}) => update_values(name, value)}
                value={values.contract}
                autoComplete="false"
              />
            </Grid>
            <Grid item xs={12}>
              <UserAutocomplete
                fullWidth
                label="Owner"
                margin="dense"
                name="owner"
                update_values={update_values}
                required
                value={values.owner}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Contractor"
                margin='dense'
                name='contractor_id'
                value={values.contractor_id}
                onChange={({target: {name, value}}) => update_values(name, value)}
                fullWidth
              >
                <MenuItem key={'empty'} value={''}/>
                {contractors.contractors.map((contractor) => (
                  <MenuItem key={contractor.id} value={contractor.id}>
                    {contractor.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider/>
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            variant={is_new ? "contained" : "outlined"}
            type="submit"
          >
            {is_new ? "Create Project" : "Save Details"}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

ProjectDetails.propTypes = {
  className: PropTypes.string
};

export default ProjectDetails;
