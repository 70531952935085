import React,
{
  forwardRef,
  useCallback,
  useEffect,
  useReducer,
  useState
} from "react";
import {useMutate} from "restful-react";
import {useSnackbar} from "notistack";
import {remove_properties_if_empty_string} from "../../../common/remove_properties_if";
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import {Measure, MeasureAttributes} from "./MeasureModel";
import validate from "validate.js";
import {get_dotted_property, set_dotted_property} from "../../../common/dotted_property_access";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  layout:        {
    width:                                              'auto',
    marginLeft:                                         theme.spacing(2),
    marginRight:                                        theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft:  'auto',
      marginRight: 'auto'
    }
  },
  button:        {
    margin: theme.spacing(1)
  },
  dialogTitle:   {
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.primary.contrastText
  },
  dialogActions: {
    backgroundColor: theme.palette.background.default
  }
}));

const schema = {
  ...Object.keys(Measure).reduce((agg, i) => ({...agg, [i]: Measure[i].validate}), {}),
  ...Object.keys(MeasureAttributes.all.attributes)
           .reduce((agg, i) => ({...agg, [`Attributes.${i}`]: MeasureAttributes.all.attributes[i].validate}), {}),
  ...Object.keys(MeasureAttributes.not_reported.attributes)
           .reduce((agg, i) => ({...agg, [`Attributes.${i}`]: MeasureAttributes.not_reported.attributes[i].validate}),
                   {}),
  ...Object.keys(MeasureAttributes.types).reduce((agg, type) => {
    return {
      ...agg,
      ...Object.keys(MeasureAttributes.types[type].attributes)
               .reduce((a, i) => ({...a, [`Attributes.${i}`]: MeasureAttributes.types[type].attributes[i].validate}),
                       {})
    };
  }, {})
};

const create_measure_model_values = () => {
  let initial = {Attributes: {}};
  Object.keys(Measure).forEach((k) => initial[k] = '');
  Object.keys(MeasureAttributes.all.attributes).forEach((k) => initial.Attributes[k] = '');
  Object.keys(MeasureAttributes.not_reported.attributes).forEach((k) => initial.Attributes[k] = '');
  Object.keys(MeasureAttributes.types).forEach((type) =>
                                                 Object.keys(MeasureAttributes.types[type].attributes)
                                                       .forEach((k) => initial.Attributes[k] = '')
  );
  return initial;
};

const values_reducer = (values, {action, payload}) => {
  let v = {...values};
  switch (action) {
    case 'update' : {
      const {name, value} = payload;
      const dependent_life_savings = [
        'SavingsGrossMeasureLife09Yr',
        'SavingsGrossMeasureLife1014Yr',
        'SavingsGrossMeasureLife15Yr'
      ];
      v.input = {...values.input};
      v.touched = {...values.touched, [name]: true};
      set_dotted_property(v.input, name, value);
      dependent_life_savings.forEach((field) => {
        const dotted_field = `Attributes.${field}`;
        values.touched[dotted_field] = false;
        set_dotted_property(v.input, dotted_field, '');
      });
      const measure_life = get_dotted_property(v.input, 'Attributes.MeasureLife');
      const savings_kw = get_dotted_property(v.input, 'Attributes.SavingskW');
      if (measure_life && savings_kw) {
        let dependent_measure = `Attributes.SavingsGrossMeasureLife15Yr`;
        if (measure_life <= 9) {
          dependent_measure = `Attributes.SavingsGrossMeasureLife09Yr`;
        } else if (measure_life < 15) {
          dependent_measure = `Attributes.SavingsGrossMeasureLife1014Yr`;
        }
        v.touched[dependent_measure] = true;
        set_dotted_property(v.input, dependent_measure, savings_kw);
      }else if(savings_kw<=0){
        dependent_life_savings.forEach((field) => {
          const dotted_field = `Attributes.${field}`;
          values.touched[dotted_field] = true;
          set_dotted_property(v.input, dotted_field, savings_kw);
        });
      }
      break;
    }
    case 'errors': {
      v.is_valid = !payload;
      v.errors = payload || {};
      break;
    }
    default: {
      console.log(`Unknown action: ${action}`);
    }
  }
  return v;
};

const init = (initial_value) => {
  return {
    is_valid: false,
    input:    initial_value,
    touched:  {Attributes: {}},
    errors:   {Attributes: {}}
  };
};

class FastMeasureField extends React.Component {

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.value !== this.props.value ||
           nextProps.show_hidden !== this.props.show_hidden ||
           nextProps.error !== this.props.error ||
           nextProps.error_helper !== this.props.error_helper;
  }

  render() {
    const {
      hidden,
      show_hidden,
      required,
      name,
      error,
      error_helper,
      type,
      description,
      on_change,
      measure_values,
      value
    } = this.props;
    return (
      hidden && !show_hidden ? null :
      <Grid item xs={12} md={6}>
        <Tooltip title={name.substring(name.indexOf('.')+1)}>
          <TextField required={required}
                     type={type === 'select' ? undefined : type}
                     select={type === 'select' ? true : undefined}
                     error={error}
                     helperText={error_helper}
                     InputLabelProps={type === 'date' || type === 'datetime-local' ?
                                      {
                                        shrink: true
                                      } :
                                      {}}
                     label={description}
                     margin='dense'
                     name={name}
                     fullWidth
                     autoComplete="off"
                     value={value}
                     onChange={on_change}>
            {type === 'select' &&
             measure_values.map((val) => (
               <MenuItem key={val.value} value={val.value}>
                 {val.display}
               </MenuItem>
             ))
            }
          </TextField>
        </Tooltip>
      </Grid>);
  }
}

export default forwardRef(({
                             open,
                             set_open,
                             measures_url,
                             measure
                           },
                           ref) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [values, dispatch] = useReducer(values_reducer,
                                        measure ? measure.attributes : create_measure_model_values(),
                                        init);
  const on_change = useCallback((event) => {
    const type = event.nativeEvent.target.getAttribute('type');
    const {name, value} = event.target;
    dispatch({
               action:  'update',
               payload: {
                 name,
                 value: (type === "number" && value !== '') ? parseFloat(value) : value
               }
             });
  }, []);
  const [show_hidden, set_show_hidden] = useState(false);
  const [show_not_reported, set_show_not_reported] = useState(false);
  const [submit_attempted, set_submit_attempted] = useState(false);
  const [measure_type, set_measure_type] = useState(measure ? measure.type : '');
  const on_measure_type = useCallback((event) => {
    set_measure_type(event.target.value);
  }, []);
  const {
    mutate: save_measure,
    error:  save_measure_error
  } = measure ?
      useMutate('PUT', `${measures_url}/${measure.id}`) :
      useMutate('POST', measures_url);
  if (save_measure_error) {
    enqueueSnackbar('Could not save measure', {variant: 'error'});
  }

  const has_error = useCallback((field) => {
    return !!((values.touched[field] || submit_attempted) && values.errors[field]);
  }, [values.touched, values.errors, submit_attempted]);

  useEffect(() => {
    const errors = validate(values.input, schema);
    dispatch({action: 'errors', payload: errors});
  }, [values.input]);

  const on_submit = () => {
    if (!values.is_valid) {
      set_submit_attempted(true);
      enqueueSnackbar('Please correct form errors', {variant: 'warning'});
    } else {
      let measure = {};
      measure.attributes = {...values.input};
      measure.attributes.Attributes = {...values.input.Attributes};
      measure.type = measure_type;
      remove_properties_if_empty_string(measure);
      save_measure({measure})
        .then(() => enqueueSnackbar('Measure Saved', {variant: 'success'}))
        .then(() => set_open(false))
        .then(() => ref.current && ref.current.onQueryChange());
    }
  };

  return (
    <Dialog open={open} onClose={() => set_open(false)} fullWidth={true} maxWidth="md">
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Typography component="h2" variant="h4" color="inherit">
          Add Measure
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.layout} dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={12} key='label.measure'>
            <Typography component="h4" variant="h4">Measure</Typography>
          </Grid>
          {
            Object.keys(Measure).map((name) => {
              const {type, description, required, hidden, values: measure_values} = Measure[name];
              return <FastMeasureField key={name}
                                       hidden={hidden}
                                       show_hidden={show_hidden}
                                       required={required}
                                       name={name}
                                       error={has_error(name)}
                                       error_helper={has_error(name) ?
                                                     values.errors[name][0] :
                                                     null}
                                       type={type}
                                       description={description}
                                       on_change={on_change}
                                       measure_values={measure_values}
                                       value={values.input[name]}/>;
            })
          }
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12} md={6} key='label.attributes'>
            <Typography component="h4" variant="h4">Measure Attributes</Typography>
          </Grid>
          <Grid item xs={12} md={6} key='select_type'>
            <TextField
              select
              label="Select Measure Type"
              margin='dense'
              value={measure_type}
              onChange={on_measure_type}
              fullWidth
            >
              {Object.keys(MeasureAttributes.types).map(type => (
                <MenuItem key={type} value={type}>
                  {MeasureAttributes.types[type].description}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} key='label.all'>
            <Typography component="h6" variant="h6">All Measure Types</Typography>
          </Grid>
          {Object.keys(MeasureAttributes.all.attributes).map((name) => {
            const {type, description, required, hidden, values: measure_values} = MeasureAttributes.all.attributes[name];
            return <FastMeasureField key={name}
                                     hidden={hidden}
                                     show_hidden={show_hidden}
                                     required={required}
                                     name={`Attributes.${name}`}
                                     error={has_error(`Attributes.${name}`)}
                                     error_helper={has_error(`Attributes.${name}`) ?
                                                   values.errors[`Attributes.${name}`][0] :
                                                   null}
                                     type={type}
                                     description={description}
                                     on_change={on_change}
                                     measure_values={measure_values}
                                     value={values.input.Attributes[name]}/>;
          })
          }
          {measure_type !== '' &&
           <>
             <Grid item xs={12}>
               <Divider/>
             </Grid>
             <Grid item xs={12} key='label.section'>
               <Typography component="h6" variant="h6">{MeasureAttributes.types[measure_type].description}</Typography>
             </Grid>
             {Object.keys(MeasureAttributes.types[measure_type].attributes).map((name) => {
               const {type, description, required, hidden, values: measure_values} = MeasureAttributes.types[measure_type].attributes[name];
               return <FastMeasureField key={name}
                                        hidden={hidden}
                                        show_hidden={show_hidden}
                                        required={required}
                                        name={`Attributes.${name}`}
                                        error={has_error(`Attributes.${name}`)}
                                        error_helper={has_error(`Attributes.${name}`) ?
                                                      values.errors[`Attributes.${name}`][0] :
                                                      null}
                                        type={type}
                                        description={description}
                                        on_change={on_change}
                                        measure_values={measure_values}
                                        value={values.input.Attributes[name]}/>;
             })}
           </>
          }
          {show_not_reported &&
           <>
             <Grid item xs={12}>
               <Divider/>
             </Grid>
             <Grid item xs={12} key='label.section'>
               <Typography component="h6" variant="h6">Fields Not Reported</Typography>
             </Grid>
             {Object.keys(MeasureAttributes.not_reported.attributes).map((name) => {
               const {type, description, required, hidden, values: measure_values} = MeasureAttributes.not_reported.attributes[name];
               return <FastMeasureField key={name}
                                        hidden={hidden}
                                        show_hidden={show_hidden}
                                        required={required}
                                        name={`Attributes.${name}`}
                                        error={has_error(`Attributes.${name}`)}
                                        error_helper={has_error(`Attributes.${name}`) ?
                                                      values.errors[`Attributes.${name}`][0] :
                                                      null}
                                        type={type}
                                        description={description}
                                        on_change={on_change}
                                        measure_values={measure_values}
                                        value={values.input.Attributes[name]}/>;
             })}
           </>
          }
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box flexGrow={1} className={classes.button}>
          <FormControlLabel
            control={
              <Switch
                checked={show_hidden}
                onChange={(event) => set_show_hidden(event.target.checked)}
                name="show_hidden"
                color="primary"
              />
            }
            label="Show Hidden Fields"
          />
          <FormControlLabel
            control={
              <Switch
                checked={show_not_reported}
                onChange={(event) => set_show_not_reported(event.target.checked)}
                name="show_not_reported"
                color="primary"
              />
            }
            label="Show Not Reported Fields"
          />
        </Box>
        <Button onClick={() => set_open(false)}
                className={classes.button}>
          Cancel
        </Button>
        <Button color="primary"
                onClick={on_submit}
                className={classes.button}>
          {measure ? 'Save' : 'Add'} Measure
        </Button>
      </DialogActions>
    </Dialog>
  );
});
