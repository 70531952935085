import React, {useEffect} from 'react';
import {useMutate, useGet} from 'restful-react';
import {useApi} from "./api_context";
import {LoadingBackdrop} from "../components";

const AuthContext = React.createContext({
                                          status: 0,
                                          body:   {user: null}
                                        });

function AuthProvider(props) {
  const {error: api_error} = useApi();
  const {
    data:    user_data,
    loading,
    refetch: update_user,
    error:   user_error
  } = useGet('/data/users/current', {localErrorOnly: true});

  useEffect(() => {
    if (api_error.status && api_error.status === 401) {
      update_user({localErrorOnly: true});
    }
  }, /* eslint-disable react-hooks/exhaustive-deps */ [api_error]);

  const {
    mutate: login,
    error:  login_error
  } = useMutate({
                  verb:     'POST',
                  path:     '/auth/sessions',
                  onMutate: update_user
                });
  const {
    mutate: logout,
    error:  logout_error
  } = useMutate({
                  verb:     "DELETE",
                  path:     '/auth',
                  onMutate: update_user
                });

  return (
    loading ? <LoadingBackdrop/> :
    <AuthContext.Provider
      value={
        {
          data:   (!user_error && !loading && user_data) ? user_data : undefined,
          update_user,
          login:  async (email, password) => {
            try {
              await login({email, password});
            } catch (e) {
            }
          },
          logout: () => logout('sessions'),
          errors: {
            user:   user_error,
            login:  login_error,
            logout: logout_error
          }
        }
      }
      {...props} />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export {AuthProvider, useAuth};
