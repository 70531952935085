import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useMutate} from "restful-react";
import {LoadingPaper} from '../../../components';
import {useSnackbar} from "notistack";
import MaterialTable from "material-table";
import ObjectTable from "./ObjectTable";
import material_table_icons from "../../../common/material_table_icons";

export default ({input, set_selected}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [data, set_data] = useState(null);
  const {
    mutate,
    loading,
    error
  } = useMutate('POST', '/data/ameren/address_lookup');

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Could not load addresses', {variant: "error"});
    }
  }, /* eslint-disable react-hooks/exhaustive-deps */[error, enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      try {
        let submission = Object.assign({}, input);
        Object.keys(submission).forEach((k) => {
          if (submission[k] === '') {
            delete submission[k];
          }
        });
        const new_data = await mutate(submission);
        set_data(new_data);
      } catch (ex) {
        set_data(null);
      }
    })();
  }, /* eslint-disable react-hooks/exhaustive-deps */ [input]);
  return (
    error ?
    <Paper elevation={0} style={{height: '50vh'}}>
      <Typography>{error.data.error}</Typography>
    </Paper> :
    !data || loading ?
    <LoadingPaper elevation={0} style={{height: '50vh'}}/> :
    <MaterialTable title={<Typography variant="h5" gutterBottom>Unit details</Typography>}
                   icons={material_table_icons}
                   columns={[
                     {
                       title:  'Account ',
                       type: "numeric",
                       field: 'ElectricAccountNumber'
                     },
                     {
                       title:  'Street',
                       field:  'BillingStreet'
                     },
                     {
                       title:  'Street2',
                       field: 'BillingStreet2'
                     },
                     {
                       title:  'City',
                       field: 'BillingCity'
                     },
                     {
                       title:  'State',
                       field: 'BillingState'
                     },
                     {
                       title:  'Postal Code',
                       field: 'BillingPostalCode'
                     }
                   ]}
                   data={data.response}
                   options={{
                     selection:            true,
                     sorting:              true,
                     showTextRowsSelected: false,
                     search:               false,
                     paging:               false
                   }}
                   onSelectionChange={(selections, row_data) => {
                     set_selected(selections.reduce((data, row) => ({
                       ...data,
                       [row.ElectricAccountNumber]: {
                         response: row,
                         name:     `${row.BillingStreet} ${row.BillingStreet2}`
                       }
                     }), {}));
                   }}
                   detailPanel={(row) => <ObjectTable row={row}/>}/>
  );
};
