import React, {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Toolbar, Hidden, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import NotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {Poll, useMutate} from "restful-react";
import {
  usePopupState,
  bindToggle,
  bindPopper
} from 'material-ui-popup-state/hooks';

import {useAuth} from '../../../../context/auth_context';
import moment from "moment";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  root:              {
    boxShadow: 'none'
  },
  menuItem:          {
    transformOrigin: 'top right',
    minWidth:        '200px'
  },
  notificationAlert: {
    padding:            theme.spacing(2),
    '& .MuiAlert-icon': {
      alignItems: 'center'
    }
  },
  logo:              {
    height: '40px',
    filter: "brightness(0) invert(1)"
  },
  flexGrow:          {
    flexGrow: 1
  },
  signOutButton:     {
    marginLeft: theme.spacing(1)
  }
}));

const NotificationList = ({notifications, refresh}) => {
  const classes = useStyles();
  const {
    mutate: delete_notification,
    error:  delete_notification_error
  } = useMutate('DELETE', '/data/notifications');
  const {enqueueSnackbar} = useSnackbar();
  useEffect(() => {
    if (delete_notification_error) {
      enqueueSnackbar('Cannot delete notification', {variant: 'error'});
    }
  }, [delete_notification_error, enqueueSnackbar]);
  return (
    <>
      {
        notifications.length === 0 ?
        <Typography className={classes.notificationAlert}>
          No Notifications
        </Typography>
                                   :
        notifications.map((notification) => (
          <Alert key={notification.id}
                 className={classes.notificationAlert}
                 severity={notification.variant}
                 onClose={async () => {
                   await delete_notification(notification.id);
                   refresh();
                 }}>
            <Typography variant="overline">{moment(notification.createdAt).fromNow()}</Typography>
            <Typography>{notification.content}</Typography>
          </Alert>
        ))
      }
    </>
  );
};

const Topbar = props => {
  const {className, onSidebarOpen, ...rest} = props;

  const classes = useStyles();
  const popup_state = usePopupState({variant: 'popper', popupId: 'notifications'});

  const {logout} = useAuth();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            className={classes.logo}
            alt="Logo"
            src="/icast_logo.png"
          />
        </RouterLink>
        <div className={classes.flexGrow}/>
        <Hidden mdDown>
          {
            <Poll path="/data/notifications"
                  interval={30000}>
              {(data, {loading, polling, error}, {start: force_poll}) => (
                !loading && !error && polling &&
                <>
                  <IconButton color="inherit"
                              {...bindToggle(popup_state)}>
                    <Badge
                      badgeContent={data.notifications.length}
                      color="secondary"
                    >
                      <NotificationsIcon/>
                    </Badge>
                  </IconButton>
                  <Popper  {...bindPopper(popup_state)}
                           disablePortal
                           placement="bottom-end"
                           transition>
                    {({TransitionProps}) => (
                      <ClickAwayListener onClickAway={popup_state.close}>
                        <Grow {...TransitionProps}>
                          <Paper className={classes.menuItem}>
                            <NotificationList notifications={data.notifications}
                                              refresh={force_poll}/>
                          </Paper>
                        </Grow>
                      </ClickAwayListener>
                    )}
                  </Popper>
                </>
              )}
            </Poll>
          }
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={logout}
          >
            <InputIcon/>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon/>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className:     PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
