import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {useAuth} from "../../../context/auth_context";
import {useGet, useMutate} from "restful-react";
import {useSnackbar} from "notistack";
import {LoadingPaper} from "../../../components";

const useStyles = makeStyles(() => ({
  root: {},
  cardActions: {
    justifyContent: 'flex-end'
  }
}));


const UserDetails = props => {
  const {
    user,
    is_new,
    is_current,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  let history = useHistory();
  const {update_user} = useAuth();
  const {enqueueSnackbar} = useSnackbar();

  const http_op = is_new ? 'POST' : 'PUT';
  const resource = is_new ? '' : `/${user.id}`;

  const {
    data:    roles,
    loading: roles_loading,
    error:   roles_error
  } = useGet('/data/user_roles');
  if (roles_error) {
    enqueueSnackbar('Could not load user roles', {variant: "error"});
  }

  const {
    mutate,
    error
  } = useMutate(http_op, `/data/users${resource}`);

  let initial_state = {
    ...{
      first_name:       '',
      last_name:        '',
      email:            '',
      title:            '',
      password:         '',
      confirm_password: '',
      roles:            []
    },
    ...user
  };
  initial_state.roles = initial_state.roles.map((role) => role.name);

  Object.keys(initial_state).forEach((k) => {
    if (initial_state[k] === null) {
      initial_state[k] = '';
    }
  });

  const [values, setValues] = useState(initial_state);
  const handleChange = (event) => {
    setValues({
                ...values,
                [event.target.name]: event.target.value
              });
  };

  const submit = async (event) => {
    event.preventDefault();
    let submission = Object.assign({}, values);
    Object.keys(submission).forEach((k) => {
      if (submission[k] === '') {
        delete submission[k];
      }
    });

    if (is_new) {
      const new_user = await mutate({user: submission});
      history.push(`/users/${new_user.user.id}`);
    } else {
      await mutate({user: submission});
      if (is_current) {
        update_user();
      }
    }
  };

  if (error) {
    enqueueSnackbar('Could not save account attributes', {variant: "error"});
  }

  return ((roles_loading) ?
          <LoadingPaper style={{height: '50vh'}}/>
                          :

          <Card
            {...rest}
            className={clsx(classes.root, className)}
          >
            <form
              autoComplete="false"
              noValidate
              onSubmit={submit}
            >
              <CardHeader
                title={is_new ? "New User" : "Account Details"}
                subheader={is_new ? "Account Details" : "Update Account Attributes"}
              />
              <Divider/>
              <CardContent>
                <Grid
                  container
                  spacing={1}
                  alignItems={"stretch"}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="First name"
                      margin="dense"
                      name="first_name"
                      onChange={handleChange}
                      required
                      value={values.first_name}
                      autoComplete="false"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Last Name"
                      margin="dense"
                      name="last_name"
                      onChange={handleChange}
                      required
                      value={values.last_name}
                      autoComplete="false"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Email Address"
                      margin="dense"
                      name="email"
                      onChange={handleChange}
                      required
                      value={values.email}
                      autoComplete="false"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Title"
                      margin="dense"
                      name="title"
                      onChange={handleChange}
                      value={values.title}
                      autoComplete="false"
                    />
                  </Grid>
                  {!is_current &&
                   <>
                     <Grid
                       item
                       md={6}
                       xs={12}
                     >
                       <TextField
                         fullWidth
                         label="Password"
                         margin="dense"
                         name="password"
                         type="password"
                         onChange={handleChange}
                         value={values.password}
                         autoComplete="new-password"
                       />
                     </Grid>
                     <Grid
                       item
                       md={6}
                       xs={12}
                     >
                       <TextField
                         fullWidth
                         label="Confirm Password"
                         margin="dense"
                         name="confirm_password"
                         type="password"
                         onChange={handleChange}
                         value={values.confirm_password}
                         autoComplete="new-password"
                       />
                     </Grid>
                   </>
                  }

                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      select
                      SelectProps={{
                        multiple:    true,
                        renderValue: (selected) => selected.join(', ')
                      }}
                      label='Roles'
                      name='roles'
                      value={values.roles}
                      onChange={handleChange}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    >
                      {roles.user_roles.map(({name}) => (
                        <MenuItem
                          key={name}
                          value={name}
                        >
                          <ListItemIcon>
                            {
                              values.roles.indexOf(name) === -1 ?
                              <CheckBoxOutlineBlankIcon color="primary"/> :
                              <CheckBoxIcon color="primary"/>
                            }
                          </ListItemIcon>
                          <ListItemText primary={name}/>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider/>
              <CardActions className={classes.cardActions}>
                <Button
                  color="primary"
                  variant={is_new ? "contained" : "outlined"}
                  type="submit"
                >
                  {is_new ? "Create User" : "Save Details"}
                </Button>
              </CardActions>
            </form>
          </Card>
  );
};

UserDetails.propTypes = {
  className: PropTypes.string
};

export default UserDetails;
