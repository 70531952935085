import React from 'react';
import {ApiProvider} from "./api_context";
import {AuthProvider} from './auth_context';
import {UserProvider} from './user_context';

export default function AppProviders({children}) {
  return (
    <ApiProvider>
      <AuthProvider>
        <UserProvider>{children}</UserProvider>
      </AuthProvider>
    </ApiProvider>
  );
}
