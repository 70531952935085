import React from "react";
import {RestfulProvider} from "restful-react";

const ApiContext = React.createContext({});

export const ApiProvider = (props) => {
  const [error, set_error] = React.useState({});
  const on_error = ({message, data, status},
                    retry,
                    response) => {
    set_error({message, status});
  };
  return (
    <ApiContext.Provider value={{error}}>
      <RestfulProvider base={'/api/'}
                       onError={on_error}
                       {...props} />
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = React.useContext(ApiContext);
  if (context === undefined) {
    throw new Error(`useApi must be used within a ApiProvider`);
  }
  return context;
};
