export default {
  /*ProjectExternalId:                          {
    type:        'text',
    description: 'Manager Project External Id (must be unique)',
    required:    false,
    hidden:      false
  },
  WorkOrderExternalId:                        {
    type:        'text',
    description: 'Manager WorkOrder External Id (must be unique)',
    required:    true,
    hidden:      false,
    validate:    {
      presence: {allowEmpty: false, message: 'is required'}
    }
  },*/
  ProjectApplicationSubmittedDate: {
    type:        'date',
    description: 'Project Application Submitted Date',
    required:    false,
    hidden:      true
  },
  ProjectApplicationClosedDate: {
    type:        'date',
    description: 'Project Application Closed Date',
    required:    false,
    hidden:      true
  },
  ProjectCancellationOtherReason:             {
    type:        'text',
    description: 'Description of Reason for Cancellation',
    required:    false,
    hidden:      true
  },
  ProjectCancellationReason:                  {
    type:        'text',
    description: 'Reason for the Cancellation',
    required:    false,
    hidden:      true
  },
  ProjectCancelledDate:                       {
    type:        'date',
    description: 'Project Cancelled Date',
    required:    false,
    hidden:      true
  },
  ProjectEligibilityDate:                     {
    type:        'date',
    description: 'Project Eligibility Date',
    required:    false,
    hidden:      true
  },
  ProjectEnrolledDate:                        {
    type:        'date',
    description: 'Project Enrolled Date',
    required:    true,
    hidden:      false,
    validate:    {
      presence: {allowEmpty: false, message: 'is required'}
    }
  },
  WorkOrderInstallCompleteDate:               {
    type:        'date',
    description: 'Work Order Completed Date',
    required:    false,
    hidden:      false,
    validate:    {
      presence: {allowEmpty: false, message: 'is required'}
    }
  },
  ProjectProgramExternalId:                   {
    type:        'text',
    description: 'External Id of the Program',
    required:    true,
    hidden:      false,
    validate:    {
      inclusion: ['AMEMLI2019', 'AMEMMR2019']
    }
  },
  ProjectRecordType:                          {
    type:        'text',
    description: 'Type of ProjectValue', // = ‘Demand_Response’
    required:    false,
    hidden:      true
  },
  ProjectStatus:                              {
    type:        'text',
    description: 'Status of the Project', // Values: Enrolled, Unenrolled
    required:    true,
    hidden:      false,
    validate:    {
      inclusion: ['Enrolled', 'Unenrolled']
    },
    default: 'Enrolled'
  },
  WorkOrderAppointmentNotes:                  {
    type:        'text',
    description: 'Appointment Notes',
    required:    false,
    hidden:      true
  },
  WorkOrderCoolingSystem:                     {
    type:        'text',
    description: 'Cooling System Type of Site',
    required:    false,
    hidden:      true
  },
  WorkOrderCopayPaymentAmount:                {
    type:        'number',
    description: 'Copay Payment Amount',
    required:    false,
    hidden:      true
  },
  WorkOrderCopayPaymentDate:                  {
    type:        'date',
    description: 'Work Order Copay Payment Date',
    required:    false,
    hidden:      true
  },
  WorkOrderCopayPaymentStatus:                {
    type:        'text',
    description: 'Copay Payment Status',
    required:    false,
    hidden:      true
  },
  WorkOrderCopayTransactionNumber:            {
    type:        'text',
    description: 'Copay Transaction Number',
    required:    false,
    hidden:      true
  },
  WorkOrderDateShipped:                       {
    type:        'datetime-local',
    description: 'Item Shipped Date ',
    required:    false,
    hidden:      true
  },
  WorkOrderFieldNotes:                        {
    type:        'text',
    description: 'Work Order Field Notes',
    required:    false,
    hidden:      true,
    default:     'Replaced with project invoice/contract'
  },
  WorkOrderFinalAppSignedDate: {
    type:        'date',
    description: 'Date the Application Was Signed',
    required:    false,
    hidden:      true
  },
  WorkOrderEmail:                             {
    type:        'email',
    description: 'Work Order Email',
    required:    false,
    hidden:      true
  },
  WorkOrderHeatingSystem:                     {
    type:        'text',
    description: 'Heating System of Site',
    required:    false,
    hidden:      true
  },
  WorkOrderHspf:                              {type: 'number', description: 'HSPF', required: false, hidden: true},
  WorkOrderNotCompletedDate:                  {
    type:        'date',
    description: 'Work Order Not Completed Date',
    required:    false,
    hidden:      true
  },
  WorkOrderNotCompletedOtherReason:           {
    type:        'text',
    description: 'Reason Work Order was not completed',
    required:    false,
    hidden:      true
  },
  WorkOrderNotCompletedReason:                {
    type:        'text',
    description: 'Reason the Work Order Was Cancelled',
    required:    false,
    hidden:      true
  },
  WorkOrderOriginalWorkOrderExternalId: {
    type:        'text',
    description: 'Original WorkOrderExternalId',
    required:    false,
    hidden:      true
  },
  WorkOrderScheduledDateTime:                 {
    type:        'datetime-local',
    description: 'Work Order Scheduled Time',
    required:    false,
    hidden:      true
  },
  WorkOrderSendToMobile:                      {
    type:        'text',
    description: 'Send To Mobile',
    required:    false,
    hidden:      true
  },
  WorkOrderTechnicianEmail:                   {
    type:        'email',
    description: 'Email of the Technician doing the work',
    required:    false,
    hidden:      true
  },
  WorkOrderPhone:                             {
    type:        'tel',
    description: 'Phone Number for Work Order',
    required:    false,
    hidden:      true
  },
  WorkOrderRecordType:                        {
    type:        'text',
    description: 'Work Order Record Type',
    required:    true,
    hidden:      true
  },
  WorkOrderSeer:                              {type: 'number', description: 'SEER', required: false, hidden: true},
  WorkOrderStatus:                            {
    type:        'text',
    description: 'Status of the Work Order',
    required:    true,
    hidden:      false,
    validate:    {
      inclusion: ['New', 'Field Completed']
    },
    default: 'Field Completed'
  },
  WorkOrderTrackingNumber:                    {
    type:        'text',
    description: 'Shipping Tracking Number',
    required:    false,
    hidden:      true
  },
  WorkOrderTrackingCarrier:                   {
    type:        'text',
    description: 'Shipping Carrier',
    required:    false,
    hidden:      true
  },
  WorkOrderType:                              {
    type:        'text',
    description: 'Type of Work Order',
    required:    false,
    hidden:      true
  },
  WorkOrderWaterHeatSource:                   {
    type:        'text',
    description: 'Source of Water Heater of Site',
    required:    false,
    hidden:      true
  },
  ExternallyModifiedBy:                        {
    type:        'text',
    description: 'Who completed/submitted Work Order',
    required:    true,
    hidden:      false,
    validate:    {
      presence: {allowEmpty: false, message: 'is required'}
    },
    default: 'CURRENT_USER'
  },
  /*CreatedBy:                                  {
    type:        'text',
    description: 'Work Order Created By',
    required:    true,
    hidden:      true,
    validate:    {
      presence: {allowEmpty: false, message: 'is required'}
    }
  },*/
  CheckNumber:                                {
    type:        'text',
    description: 'Issued check number',
    required:    false,
    hidden:      true
  },
  CheckAmount:                                {
    type:        'number',
    description: 'Issued check amount',
    required:    false,
    hidden:      true
  },
  CheckStatus:                                {
    type:        'text',
    description: 'Issued check status',
    required:    false,
    hidden:      true
  },
  DateCashed:                                 {
    type:        'datetime-local',
    description: 'Date check cashed',
    required:    false,
    hidden:      true
  },
  PaymentDate:                                {
    type:        'datetime-local',
    description: 'Issued date',
    required:    false,
    hidden:      true
  }
};