import React, {forwardRef, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {useSnackbar} from "notistack";
import {useMutate} from "restful-react";
import MaterialTable from "material-table";
import material_table_icons from "../../common/material_table_icons";
import material_table_query from "../../common/material_table_query";
import AddOutline from '@material-ui/icons/AddOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Grid from "@material-ui/core/Grid";
import BuildIcon from "@material-ui/icons/Build";


const useStyles = makeStyles(theme => ({
  root:    {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

export default () => {
  const classes = useStyles();
  let history = useHistory();
  let table_ref = useRef();
  const {enqueueSnackbar} = useSnackbar();
  const {
    mutate: delete_contractor,
    error:  delete_error
  } = useMutate('DELETE', `/data/contractors`);
  useEffect(() => {
    if (delete_error) {
      enqueueSnackbar('Cannot delete contractor', {variant: 'error'});
    }
  }, [delete_error, enqueueSnackbar]);
  const {
    mutate: create_contractor,
    error:  create_error
  } = useMutate('POST', `/data/contractors`);
  useEffect(() => {
    if (create_error) {
      enqueueSnackbar('Cannot create contractor', {variant: 'error'});
    }
  }, [create_error, enqueueSnackbar]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <MaterialTable
          title={
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item>
                <BuildIcon/>
              </Grid>
              <Grid item>
                Contractors
              </Grid>
            </Grid>
          }
          icons={{
            ...material_table_icons,
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color='action'/>),
            Add:    forwardRef((props, ref) => <AddOutline {...props} ref={ref} color='action'/>)
          }}
          tableRef={table_ref}
          columns={[
            {
              title:    'ID',
              field:    'id',
              type:     "numeric",
              editable: "never"
            },
            {
              title: 'Name',
              field: 'name'
            },
            {
              title: 'External ID',
              field: 'external_id'
            },
            {
              title:    'Created',
              field:    'createdAt',
              type:     'date',
              editable: 'never'
            }
          ]}
          data={material_table_query({
                                       url:      '/api/data/contractors',
                                       data_key: 'contractors',
                                       on_error: () => history.push('/')
                                     })}
          actions={[
            {
              icon: () => <DeleteOutline color='action'/>,
              tooltip: 'Delete Contractors',
              onClick: async (event, rows) => {
                for (const row of rows) {
                  await delete_contractor(row.id);
                }
                enqueueSnackbar('Contractors deleted', {variant: "success"});
                table_ref.current.onQueryChange();
              }
            }
          ]}
          editable={{
            onRowUpdate: undefined,
            onRowAdd:    async (row) => {
              await create_contractor({contractor: row});
              enqueueSnackbar('Contractor created', {variant: "success"});
              table_ref.current.onQueryChange();
            },
            onRowDelete: async (row) => {
              await delete_contractor(row.id);
              enqueueSnackbar('Contractor deleted', {variant: "success"});
              table_ref.current.onQueryChange();
            }
          }}
          options={{
            actionsColumnIndex:  -1,
            search:              false,
            emptyRowsWhenPaging: false,
            pageSize:            10,
            pageSizeOptions:     [10, 25, 50],
            selection:           true
          }}
        />

      </div>
    </div>
  );
};
