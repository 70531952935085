import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import {useMutate} from "restful-react";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(() => ({
  root: {},
  cardActions: {
    justifyContent: 'flex-end'
  }
}));

const Password = props => {
  const {className, ...rest} = props;
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();

  const {
    mutate,
    error
  } = useMutate('PUT', '/data/users/current/password');

  const [values, setValues] = useState({
                                         old_password:         '',
                                         new_password:         '',
                                         new_password_confirm: ''
                                       });

  const handleChange = event => {
    setValues({
                ...values,
                [event.target.name]: event.target.value
              });
  };

  const submit = (event) => {
    event.preventDefault();
    mutate(values).then(() => {
      setValues({
                  old_password:         '',
                  new_password:         '',
                  new_password_confirm: ''
                });
    });
  };

  if (error) {
    enqueueSnackbar('Could not update password', {variant: "error"});
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="false"
        noValidate
        onSubmit={submit}
      >
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider/>
        <CardContent>
          <TextField
            fullWidth
            label="Old Password"
            name="old_password"
            onChange={handleChange}
            type="password"
            value={values.old_password}
            margin={"dense"}
            variant="outlined"
            autoComplete="new-password"
          />
          <TextField
            fullWidth
            label="New password"
            name="new_password"
            onChange={handleChange}
            style={{marginTop: '1rem'}}
            type="password"
            value={values.new_password}
            margin={"dense"}
            variant="outlined"
            autoComplete="new-password"
          />
          <TextField
            fullWidth
            label="Confirm password"
            name="new_password_confirm"
            onChange={handleChange}
            style={{marginTop: '1rem'}}
            type="password"
            value={values.new_password_confirm}
            margin={"dense"}
            variant="outlined"
            autoComplete="new-password"
          />
        </CardContent>
        <Divider/>
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
