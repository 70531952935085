import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';
import {LoadingPaper, useResourceParams, withResourceShell} from "../../components";
import {UserDetails, UserPassword} from './components';
import {useUser} from "../../context/user_context";
import {useGet} from "restful-react";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const User = () => {
  const classes = useStyles();
  const current_user = useUser();
  const resource_params = useResourceParams();
  const {id, is_new} = resource_params;
  const is_current = current_user && (current_user.id === id);
  const {
    data,
    loading,
    refetch
    //error
  } = useGet({path: `/data/users/${id}`, lazy: true});
  if (!is_new && !is_current && !loading && !data) {
    refetch().then();
  }
  const user = is_current ? current_user : is_new ? {} : data ? data.user : {id};
  return !is_new && (!is_current && (!data || loading)) ?
         <LoadingPaper style={{height: '50vh'}}/>
                                           :
         (
           <div className={classes.root}>
             <Grid container spacing={3}>
               <Grid item xs={12} lg={is_current ? 8 : 12}>
                 <UserDetails
                   {...resource_params}
                   is_current={is_current}
                   user={user}
                 />
               </Grid>
               {is_current &&
                <Grid item xs={12} lg={4}>
                  <UserPassword/>
                </Grid>
               }
             </Grid>
           </div>
         );
};

export default withResourceShell(User);
