import React, {useCallback, useEffect, useReducer, useState} from "react";
import {useMutate} from "restful-react";
import {useSnackbar} from "notistack";
import {remove_properties_if_empty_string} from "../../../common/remove_properties_if";
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import WorkOrder from "./WorkOrderModel";
import validate from "validate.js";
import {useUser} from "../../../context/user_context";

const useStyles = makeStyles(theme => ({
  layout:        {
    width:                                              'auto',
    marginLeft:                                         theme.spacing(2),
    marginRight:                                        theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft:  'auto',
      marginRight: 'auto'
    }
  },
  button:        {
    margin: theme.spacing(1)
  },
  dialogTitle:   {
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.primary.contrastText
  },
  dialogActions: {
    backgroundColor: theme.palette.background.default
  }
}));

const schema = Object.keys(WorkOrder)
                     .reduce((agg, i) => ({...agg, [i]: WorkOrder[i].validate}), {});

const workorder_defaults = ({user}) => {
  let initial = {};
  Object.keys(WorkOrder).forEach((k) => {
    if (WorkOrder[k].default) {
      if (WorkOrder[k].default === 'CURRENT_USER') {
        initial[k] = user.email;
      } else {
        initial[k] = WorkOrder[k].default;
      }
    } else {
      initial[k] = '';
    }
  });
  return initial;
};

const init = (ctx) => (initial_value) => {
  return {
    is_valid: false,
    input:    {
      ...workorder_defaults(ctx),
      ...initial_value
    },
    touched:  {},
    errors:   {}
  };
};

const values_reducer = (ctx) => (values, {action, payload}) => {
  let v = {...values};
  switch (action) {
    case 'update' : {
      const {name, value} = payload;
      v.input = {...values.input, [name]: value};
      v.touched = {...values.touched, [name]: true};
      break;
    }
    case 'errors': {
      v.is_valid = !payload;
      v.errors = payload || {};
      break;
    }
    case 'reset': {
      v = init(ctx)(payload);
      break;
    }
    default: {
      console.log(`Unknown action: ${action}`);
    }
  }
  return v;
};

export default ({open, set_open, project, reload_project}) => {
  const classes = useStyles();
  const user = useUser();
  const {enqueueSnackbar} = useSnackbar();
  const [values, dispatch] = useReducer(values_reducer({user}),
                                        project.workorder,
                                        init({user}));
  const on_change = useCallback((event) => {
    const type = event.target.getAttribute('type');
    const {name, value} = event.target;
    dispatch({
               action:  'update',
               payload: {
                 name,
                 value: (type === "number") ? parseFloat(value) : value
               }
             });
  }, []);
  const [show_hidden, set_show_hidden] = useState(false);
  const [submit_attempted, set_submit_attempted] = useState(false);
  const {
    mutate: update_project,
    error:  update_project_error
  } = useMutate('PUT', `/data/projects/${project.id}`);
  useEffect(() => {
    if (update_project_error) {
      enqueueSnackbar('Could not update project', {variant: 'error'});
    }
  }, [update_project_error, enqueueSnackbar]);

  const has_error = useCallback((field) => {
    return !!((values.touched[field] || submit_attempted) && values.errors[field]);
  }, [values.touched, values.errors, submit_attempted]);
  useEffect(() => {
    const errors = validate(values.input, schema);
    dispatch({action: 'errors', payload: errors});
  }, [values.input]);

  const on_submit = () => {
    if (!values.is_valid) {
      set_submit_attempted(true);
      enqueueSnackbar('Please correct form errors', {variant: 'warning'});
    } else {
      let project = {};
      project.workorder = {...values.input};
      remove_properties_if_empty_string(project);
      update_project({project})
        .then(() => enqueueSnackbar('Project Updated',
                                    {variant: 'success'}))
        .then(() => set_open(false))
        .then(reload_project);
    }
  };

  return (
    <Dialog open={open}
            onClose={() => {
              dispatch({action: 'reset', payload: project.workorder});
              set_open(false);
            }}
            fullWidth={true}
            maxWidth="md">
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Typography component="h2" variant="h4" color="inherit">
          Update WorkOrder Data
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.layout} dividers={true}>
        <Grid container spacing={1}>
          {
            Object.keys(WorkOrder).map((name) => {
              const {type, description, required, hidden} = WorkOrder[name];
              return (
                hidden && !show_hidden ? null :
                <Grid item xs={12} md={6} key={name}>
                  <TextField required={required}
                             error={has_error(name)}
                             helperText={has_error(name) ? values.errors[name][0] : null}
                             type={type}
                             InputLabelProps={type === 'date' || type === 'datetime-local' ?
                                              {
                                                shrink: true
                                              } :
                                              {}}
                             label={description}
                             margin='dense'
                             name={name}
                             fullWidth
                             autoComplete="off"
                             value={values.input[name]}
                             onChange={on_change}/>
                </Grid>);
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box flexGrow={1} className={classes.button}>
          <FormControlLabel
            control={
              <Switch
                checked={show_hidden}
                onChange={(event) => set_show_hidden(event.target.checked)}
                name="show_hidden"
                color="primary"
              />
            }
            label="Show Hidden Fields"
          />
        </Box>
        <Button onClick={() => {
          set_open(false);
          dispatch({action: 'reset', payload: project.workorder});
        }}
                className={classes.button}>
          Cancel
        </Button>
        <Button color="primary"
                onClick={on_submit}
                className={classes.button}>
          Update Workorder
        </Button>
      </DialogActions>
    </Dialog>
  );
};
