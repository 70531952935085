import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import {SnackbarProvider} from 'notistack';

import validate from 'validate.js';
import validators from './common/validators';

import AppProviders from "./context";
import theme from './theme';
import Routes from './Routes';

import 'react-perfect-scrollbar/dist/css/styles.css';

validate.validators = {
  ...validate.validators,
  ...validators
};

export default () => {
  return (
    <AppProviders>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={5}>
          <BrowserRouter>
            <Routes/>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </AppProviders>
  );
};
