import React from 'react';
import {useAuth} from './auth_context';

const UserContext = React.createContext({data: {user: null}});

const UserProvider = (props) => {
  const {
    data: { user } = { user: null }
  } = useAuth();
  return <UserContext.Provider value={user} {...props} />;
};

const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

const hasRole = (user, role) => {
  return user&& user.roles && user.roles.some((user_role) => user_role.name === role);
};

const RoleRequired = ({role, children}) => {
  const user = useUser();
  const has_role = hasRole(user, role);
  console.log('User: ', user, 'Role: ', role, 'Has Role: ', has_role, 'Children: ', children);
  return null;
};

export {UserProvider, useUser, hasRole, RoleRequired};
