import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import titleize from "titleize";
import humanizeString from "humanize-string";
import Table from "@material-ui/core/Table";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    noborder: {
      border: 'none'
    }
  };
});

export default ({row}) => {
  const classes = useStyles();
  return (
    <Table size="small">
      <TableBody>
        {
          (() => {
            let rows = [];
            const keys = Object.keys(row);
            for (let i = 0; i < keys.length;) {
              if (i === keys.length - 1) {
                rows.push(
                  <TableRow key={keys[i]}>
                    <TableCell className={classes.noborder}>
                      <Typography style={{fontWeight: 'bold', textAlign: "right"}}>
                        {titleize(humanizeString(keys[i]))}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.noborder}>
                      {(row[keys[i]] || '').toString()}
                    </TableCell>
                    <TableCell className={classes.noborder}/>
                    <TableCell className={classes.noborder}/>
                  </TableRow>
                );
                ++i;
              } else {
                rows.push(
                  <TableRow key={keys[i]}>
                    <TableCell className={classes.noborder}>
                      <Typography style={{fontWeight: 'bold', textAlign: "right"}}>
                        {titleize(humanizeString(keys[i]))}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.noborder}>
                      {(row[keys[i]] || '').toString()}
                    </TableCell>
                    <TableCell className={classes.noborder}>
                      <Typography style={{fontWeight: 'bold', textAlign: "right"}}>
                        {titleize(humanizeString(keys[i + 1]))}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.noborder}>
                      {(row[keys[i + 1]] || '').toString()}
                    </TableCell>
                  </TableRow>
                );
                i += 2;
              }
            }
            return rows;
          })()
        }
      </TableBody>
    </Table>
  );
};
