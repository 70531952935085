import React, {forwardRef, memo, useCallback, useEffect, useReducer, useRef, useState} from "react";
import {useHistory} from 'react-router-dom';
import MaterialTable from "material-table";
import material_table_icons from "../../../common/material_table_icons";
import Paper from "@material-ui/core/Paper";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import EditIcon from '@material-ui/icons/EditOutlined';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescentOutlined';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import material_table_query from "../../../common/material_table_query";
import MeasureBuilder from "./MeasureBuilder";
import {useMutate} from "restful-react";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ObjectTable from "./ObjectTable";
import titleize from "titleize";
import humanizeString from "humanize-string";
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {sort_object_keys_same} from "../../../common/sort_object_keys_same";
import {Measure, MeasureAttributes} from "./MeasureModel";

const useStyles = makeStyles((theme) => ({
  detailPaper:   {
    padding: theme.spacing(2)
  },
  layout:        {
    width:                                              'auto',
    marginLeft:                                         theme.spacing(2),
    marginRight:                                        theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft:  'auto',
      marginRight: 'auto'
    }
  },
  button:        {
    margin: theme.spacing(1)
  },
  dialogTitle:   {
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.primary.contrastText
  },
  dialogActions: {
    backgroundColor: theme.palette.background.default
  }
}));

const QuantityChange = ({open, set_open, measures_url, measure_data}) => {
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();
  const {
    mutate: update_quantity,
    error:  update_quantity_error
  } = useMutate('PATCH', `${measures_url}/${measure_data.id}/change_quantity`);
  useEffect(() => {
    if (update_quantity_error) {
      enqueueSnackbar('Could not update measure quantity', {variant: 'error'});
    }
  }, [update_quantity_error, enqueueSnackbar]);
  const [new_quantity, set_new_quantity] = useState(measure_data.quantity);
  const on_submit = async () => {
    await update_quantity({new_quantity});
    enqueueSnackbar('Quantity Updated', {variant: "success"});
    set_open(false);
  };
  return (
    <Dialog open={open} onClose={() => set_open(false)}>
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Typography component="h2" variant="h4" color="inherit">
          Update Measure Quantity
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.layout} dividers={true}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField type={'number'}
                       label='Current Quantity'
                       margin='dense'
                       fullWidth
                       autoComplete="off"
                       value={measure_data.quantity}
                       disabled={true}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField required={true}
                       type={'number'}
                       error={new_quantity < 1}
                       helperText={(new_quantity < 1) ? 'Quantity cannot be less than 1' : null}
                       label='New Quantity'
                       margin='dense'
                       fullWidth
                       autoComplete="off"
                       inputProps={{min: 1}}
                       value={new_quantity}
                       onChange={(event) => set_new_quantity(parseFloat(event.target.value))}/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => set_open(false)}
                className={classes.button}>
          Cancel
        </Button>
        <Button color="primary"
                onClick={on_submit}
                className={classes.button}>
          Update Workorder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MeasureDetails = ({measure_data}) => {
  const classes = useStyles();
  let measure_only = {...measure_data.attributes};
  delete measure_only.Attributes;
  let all_attributes = Object.keys(measure_data.attributes.Attributes)
                             .filter((i) => Object.keys(MeasureAttributes.all.attributes).includes(i))
                             .reduce((a, i) => ({...a, [i]: measure_data.attributes.Attributes[i]}), {});
  let type_attributes = Object.keys(measure_data.attributes.Attributes)
                              .filter((i) => Object.keys(MeasureAttributes.types[measure_data.type].attributes)
                                                   .includes(i))
                              .reduce((a, i) => ({...a, [i]: measure_data.attributes.Attributes[i]}), {});
  let not_reported_attributes = Object.keys(measure_data.attributes.Attributes)
                                      .filter((i) => Object.keys(MeasureAttributes.not_reported.attributes).includes(i))
                                      .reduce((a, i) => ({...a, [i]: measure_data.attributes.Attributes[i]}), {});
  return (
    <Paper className={classes.detailPaper} elevation={0}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Measure
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ObjectTable row={sort_object_keys_same(measure_only, Measure)}/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Measure Attributes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ObjectTable row={{
            ...sort_object_keys_same(all_attributes, MeasureAttributes.all.attributes),
            ...sort_object_keys_same(type_attributes, MeasureAttributes.types[measure_data.type].attributes),
            ...sort_object_keys_same(not_reported_attributes, MeasureAttributes.not_reported.attributes)
          }}/>
        </Grid>
      </Grid>
    </Paper>
  );
};

const MeasureTableI = memo(forwardRef(
  ({
     measures_url,
     title,
     actions,
     set_open_new_measure,
     set_quantity_measure,
     edited_measure_dispatch,
     ...props
   }, ref) => {
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const {
      mutate: delete_measure,
      error:  delete_error
    } = useMutate('DELETE', measures_url);
    useEffect(() => {
      if (delete_error) {
        enqueueSnackbar('Could not delete measure', {variant: 'error'});
      }
    }, [delete_error, enqueueSnackbar]);
    const {
      mutate: copy_measure,
      error:  copy_error
    } = useMutate('PUT', measures_url);
    useEffect(() => {
      if (copy_error) {
        enqueueSnackbar('Could not copy measure', {variant: 'error'});
      }
    }, [copy_error, enqueueSnackbar]);
    return (
      <MaterialTable
        title={
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item>
              <WbIncandescentIcon/>
            </Grid>
            <Grid item>
              {title}
            </Grid>
          </Grid>
        }
        tableRef={ref}
        icons={{
          ...material_table_icons,
          Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color='action'/>)
        }}
        components={{
          Container: ({children}) => <Paper {...props}>{children}</Paper>
        }}
        columns={[
          {
            title: 'ID',
            field: 'id',
            type:  "numeric"
          },
          {
            title:  'Measure Type',
            field:  'type',
            render: (row) => titleize(humanizeString(row.type))
          },
          {
            title:  'Program Measure External ID',
            render: (row) => row.attributes.ProgramMeasureExternalId
          },
          {
            title:  'TRM Description',
            render: (row) => row.attributes.TRMDescription
          }
        ]}
        data={material_table_query({
                                     url:      `/api/${measures_url}`,
                                     data_key: 'measures',
                                     on_error: () => history.push('/')
                                   })}
        actions={[
          {
            icon:         () => <AddBox color='primary'/>,
            tooltip:      'Add Measure',
            isFreeAction: true,
            onClick:      () => set_open_new_measure(true)
          },
          {
            icon:     () => <EditIcon color='action'/>,
            tooltip:  'Edit Measure',
            position: 'row',
            onClick:  (event, row) => edited_measure_dispatch({action: 'set', payload: row})
          },
          {
            icon:     () => <CopyIcon color='action'/>,
            tooltip:  'Copy Measure',
            position: 'row',
            onClick:  async (event, row) => {
              await copy_measure({}, {queryParams: {action: 'copy', measureId: row.id}});
              enqueueSnackbar('Measure copied', {variant: 'success'});
              ref.current.onQueryChange();
            }
          },
          {
            icon:     () => <ExposureOutlinedIcon color="action"/>,
            tooltip:  'Edit Quantity',
            position: 'row',
            onClick:  (event, row) => {
              set_quantity_measure(row);
            }
          },
          {
            icon:    () => <DeleteOutline color='action'/>,
            tooltip: 'Delete Measures',
            onClick: async (event, rows) => {
              for (const row of rows) {
                await delete_measure(row.id);
              }
              enqueueSnackbar('Measures deleted', {variant: "success"});
              ref.current.onQueryChange();
            }
          },
          ...(actions || [])
        ]}
        editable={{
          onRowUpdate: undefined,
          onRowAdd:    undefined,
          onRowDelete: async (row) => {
            await delete_measure(row.id);
            ref.current.onQueryChange();
            enqueueSnackbar('Measure Deleted', {variant: "success"});
          }
        }}
        options={{
          actionsColumnIndex:  -1,
          sorting:             false,
          search:              false,
          emptyRowsWhenPaging: false,
          pageSize:            25,
          pageSizeOptions:     [25, 50, 100],
          selection:           true
        }}
        detailPanel={
          [
            {
              tooltip: 'Measure Details',
              render:  (row) => <MeasureDetails measure_data={row}/>
            }
          ]
        }
      />
    );
  }));

const key_reducer = (value) => {
  return ++value;
};

const edited_measure_reducer = (value, {action, payload}) => {
  switch (action) {
    case 'set':
      return payload;
    case 'reset':
      return null;
    default:
      console.log(`Unknown action: ${action}`);
  }
};

export default ({measures_url, title = 'Measures', ...props}) => {
  const table_ref = useRef();
  const [new_measure_key, increment_new_measure_key] = useReducer(key_reducer, 0);
  const [edited_measure, edited_measure_dispatch] = useReducer(edited_measure_reducer, null);
  const [open_new_measure, set_open_new_measure_] = useState(false);
  const set_open_new_measure = useCallback((val) => {
    if (!val) {
      increment_new_measure_key();
    }
    set_open_new_measure_(val);
  }, []);
  const [quantity_measure, set_quantity_measure] = useState(null);

  return (
    <>
      <MeasureTableI measures_url={measures_url}
                     set_open_new_measure={set_open_new_measure}
                     edited_measure_dispatch={edited_measure_dispatch}
                     set_quantity_measure={set_quantity_measure}
                     title={title}
                     ref={table_ref}
                     {...props}/>
      <MeasureBuilder key={`MeasureBuilder.${new_measure_key}`}
                      measures_url={measures_url}
                      open={open_new_measure}
                      set_open={set_open_new_measure}
                      ref={table_ref}/>
      {
        edited_measure &&
        <MeasureBuilder measure={edited_measure}
                        measures_url={measures_url}
                        open={true}
                        set_open={() => edited_measure_dispatch({action: 'reset'})}
                        ref={table_ref}/>
      }
      {
        quantity_measure &&
        <QuantityChange measure_data={quantity_measure}
                        measures_url={measures_url}
                        open={true}
                        set_open={() => set_quantity_measure(null)}/>
      }
    </>
  );
};
