import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  scaledfury: {
    fontFamily: `Lato, ${theme.typography.fontFamily}`
  },
  scaledfuryGrey: {
    color: theme.palette.grey[600]
  },
  scaledfuryRed: {
    color: '#ee3834'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography className={classes.scaledfury} variant="body1">
        &copy;{' '}
        <Link
          component="a"
          href="https://scaledfury.com/"
          target="_blank"
        >
          <Typography className={clsx(classes.scaledfury, classes.scaledfuryGrey)} component={'span'}>scaled</Typography>
          <Typography className={clsx(classes.scaledfury, classes.scaledfuryRed)} component={'span'}>fury</Typography>
        </Link>
        {moment().format(' Y')}
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
