import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useGet} from "restful-react";
import {useSnackbar} from "notistack";

export default ({value, update_values, ...rest}) => {
  const [open, setOpen] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const {
    data, loading, error
  } = useGet('/data/users');
  useEffect(() => {
    if (error) {
      enqueueSnackbar('Cannot load project owners', {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);

  return (
    <Autocomplete
      value={value}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event, value) => update_values('owner', value)}
      options={data && data.users ? data.users.map((u) => u.email) : []}
      loading={!data || loading}
      renderInput={params => (
        <TextField
          {...params}
          {...rest}
          label="Project Owner"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20}/> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
          }}
        />)
      }
    />
  );
}
