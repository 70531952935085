import React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Paper
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color:  '#fff'
  }
}));

export const Loading = (props) => {
  return (
    <CircularProgress
      {...props}
      color="inherit"/>
  );
};

export const LoadingPaper = (props) => {
  return (
    <Paper {...props}>
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
      <Loading/>
      </Box>
    </Paper>);
};

export const LoadingBackdrop = (props) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Loading/>
    </Backdrop>
  );
};
