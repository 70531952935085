import React, {useState, forwardRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import ResponseSelection from './ResponseSelection';
import Review from './Review';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {useMutate} from "restful-react";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  layout:        {
    width:                                              'auto',
    marginLeft:                                         theme.spacing(2),
    marginRight:                                        theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      //width: 600,
      marginLeft:  'auto',
      marginRight: 'auto'
    }
  },
  stepper:       {
    padding: theme.spacing(3)
  },
  button:        {
    margin: theme.spacing(1)
  },
  dialogTitle:   {
    backgroundColor: theme.palette.primary.main,
    color:           theme.palette.primary.contrastText
  },
  dialogActions: {
    backgroundColor: theme.palette.background.default
  }
}));

const steps = ['Input', 'Select Options', 'Review Selection'];

export default forwardRef((props, ref) => {
  const {project, open, set_open} = props;
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);
  const [input_values, set_input_values] = useState({
                                                      ClientKey:     'Ameren',
                                                      AccountNumber: '',
                                                      MeterNumber:   '',
                                                      PremiseNumber: '',
                                                      CustomerName:  '',
                                                      HouseNumber:   '',
                                                      StreetName:    '',
                                                      CityName:      '',
                                                      StateCode:     '',
                                                      PostalCode:    '',
                                                      PhoneNumber:   ''
                                                    });
  const [selected_responses, set_selected_responses] = useState({});
  const {
    mutate: create_unit,
    error:  create_unit_error
  } = useMutate('POST', `/data/projects/${project.id}/units`);

  if (create_unit_error) {
    enqueueSnackbar('Could not create unit', {variant: 'error'});
  }

  const on_submit = async () => {
    await Promise.all(
      Object.keys(selected_responses).map(async (s) => {
        await create_unit({unit: {
                            project_id: project.id,
                            input:      input_values,
                            ...selected_responses[s]
                          }});
      }));
    enqueueSnackbar('Measure Created', {variant: 'success'});
    set_open(false);
    ref.current.onQueryChange();
  };

  const on_next = () => {
    setActiveStep(activeStep + 1);
  };

  const on_back = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Dialog open={open} onClose={() => set_open(false)} fullWidth={true} maxWidth="md">
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Typography component="h2" variant="h4" color="inherit">
          Add Units
        </Typography>
      </DialogTitle>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <DialogContent className={classes.layout} dividers={true}>
        {activeStep === steps.length ? (
          <>
            <Typography variant="h5" gutterBottom>
              Lookup is complete
            </Typography>
          </>
        ) : (
           <>
             {
               (() => {
                 switch (activeStep) {
                   case 0:
                     return <AddressForm values={input_values}
                                         set_values={set_input_values}/>;
                   case 1:
                     return <ResponseSelection input={input_values}
                                               set_selected={set_selected_responses}/>;
                   case 2:
                     return <Review input={input_values}
                                    selected={selected_responses}
                                    set_selected={set_selected_responses}/>;
                   default:
                     throw new Error('Unknown step');
                 }
               })()
             }
           </>
         )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {activeStep !== 0 && (
          <Button onClick={on_back} className={classes.button}>
            Back
          </Button>
        )}
        <Button
          color="primary"
          onClick={activeStep === steps.length - 1 ? on_submit : on_next}
          className={classes.button}
        >
          {activeStep === steps.length - 1 ? 'Add To Project' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
