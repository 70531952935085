import React, {useState, useRef, useEffect} from "react";
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import SendIcon from "@material-ui/icons/SendOutlined";
import WorkorderBuilder from "./WorkorderBuilder";
import MaterialTable from "material-table";
import material_table_query from "../../../common/material_table_query";
import material_table_icons from "../../../common/material_table_icons";
import Paper from "@material-ui/core/Paper";
import {useSnackbar} from "notistack";
import {useMutate} from "restful-react";

const useStyles = makeStyles((theme) => ({
  root:        {
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  cardContent: {
    padding: theme.spacing(2, 0)
  },
  cardActions: {
    justifyContent: 'flex-end'
  }
}));

const WorkorderDetails = ({project, reload_project, ...rest}) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const [workorder_open, set_workorder_open] = useState(false);
  const tableRef = useRef();
  const {
    mutate: create_submission,
    error:  create_submission_error
  } = useMutate('POST', `/data/projects/${project.id}/workorder_submissions`);
  useEffect(() => {
    if (create_submission_error) {
      enqueueSnackbar('Could not submit work order', {variant: 'error'});
    }
  }, [create_submission_error, enqueueSnackbar]);

  return (
    <>
      <Card
        {...rest}
        className={classes.root}
      >
        <CardHeader title='Work Order'
                    subheader="Submission Details"/>
        <Divider/>
        <CardContent className={classes.cardContent}>
          <MaterialTable
            tableRef={tableRef}
            components={{
              Container: ({children}) => <Paper className={classes.root} elevation={0}>{children}</Paper>
            }}
            icons={material_table_icons}
            columns={[
              {
                title: 'ID',
                field: 'id',
                type:  "numeric"
              },
              {
                title: 'Unit ID',
                field: 'unit_id'
              },
              {
                title: 'Submitted At',
                field: 'createdAt',
                type:  'datetime'
              },
              {
                title: 'Status',
                field: 'response',
                render: (row) => (row.response === null ? 'Failed' : 'Succeeded')
              }
            ]}
            data={material_table_query({url: `/api/data/projects/${project.id}/workorder_submissions`,
                                        data_key: 'workorder_submissions',
                                        on_error: () => history.push('/')})}
            detailPanel={[
              {
                tooltip: 'Submission Results',
                render: (row) => (
                  <Paper elevation={0}>
                    <Box m={2}>
                      {row.response ? 'Submission Succeeded' : row.error ?
                                                               row.error.toString() : 'Submission Failed'}
                    </Box>
                  </Paper>)
              }
            ]}
            options={{
              toolbar:             false,
              actionsColumnIndex:  -1,
              sorting:             false,
              search:              false,
              emptyRowsWhenPaging: false,
              padding:             'dense'
            }}
          />
        </CardContent>
        <Divider/>
        <CardActions className={classes.cardActions}>
          <Button variant="outlined"
                  color="primary"
                  onClick={() => set_workorder_open(true)}
                  startIcon={project.status !==
                             'created' ? <VisibilityIcon/> : <EditIcon/>}>
            {project.status !==
             'created' ? 'View' : 'Edit'} WorkOrder
          </Button>
          <Button variant="contained"
                  color="primary"
                  disabled={project.workorder === null}
                  onClick={async () => {
                    await create_submission({});
                    tableRef.current.onQueryChange();
                    enqueueSnackbar('Work Order Submitted', {variant: "success"});
                  }}
                  startIcon={<SendIcon/>}>
            Submit WorkOrder
          </Button>
        </CardActions>
      </Card>
      <WorkorderBuilder open={workorder_open}
                        set_open={set_workorder_open}
                        project={project}
                        reload_project={reload_project}/>
    </>
  );
};

export default WorkorderDetails;