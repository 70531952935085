import React, {useEffect, useMemo, useState} from 'react';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapsProvider,
} from 'react-azure-maps';
import {AuthenticationType, data} from 'azure-maps-control';
import {useSnackbar} from 'notistack';
import {LoadingPaper} from '../../../components';
import key from '../../../config/key';

const AZURE_MAPS_KEY = key;
const option = {
  showBuildingModels: true,
  showLogo: false,
  style: 'satellite_road_labels',
  center: [],
  authOptions: {
    authType: AuthenticationType.subscriptionKey,
    subscriptionKey: AZURE_MAPS_KEY,
  },
};

const controls = [
  {
    controlName: 'StyleControl',
    controlOptions: {mapStyles: 'all'},
    options: {position: 'top-right'},
  },
  {
    controlName: 'ZoomControl',
    controlOptions: {mapStyles: 'all'},
    options: {position: 'bottom-right'},
  },
  {
    controlName: 'CompassControl',
    controlOptions: {mapStyles: 'all'},
    options: {position: 'top-right'},
  }
]

function clusterClicked(e) {
  console.log('clusterClicked', e);
}
const onClick = e => {
  console.log('You click on: ', e);
};

const memoizedOptions = {
  textOptions: {
    textField: ['get', 'title'],
    offset: [0, 2],
    color: '#ffffff',
    size: 18,
    haloWidth: 0.3,
    haloColor: '#0a2299',
  },
  iconOptions: {
    image: 'pin-blue',
    size: 1.3,
  },
  visible: true,
};

const renderPoint = marker => {
  const rendId = Math.random();
  return React.createElement(AzureMapFeature, {
    key: rendId,
    id: rendId.toString(),
    type: 'Point',
    coordinate: marker.coordinates,
    properties: {
      title: marker.title,
      icon: 'pin-blue',
      // offset: [0, -7],
    },
  });
};

const MapView = ({address}) => {
  const [markers, setMarkers] = useState([]);
  const [markersLayer] = useState('SymbolLayer');
  const [layerOptions, setLayerOptions] = useState(memoizedOptions);
  const [loading, setLoading] = useState(true);

  let url =
    'https://atlas.microsoft.com/search/address/json?subscription-key=' +
    AZURE_MAPS_KEY +
    '&api-version=1.0&query=' +
    address;

  const memoizedMarkerRender = useMemo(
    () =>
      markers
        .filter(function(el) {
          return el != null && el;
        })
        .map(marker => renderPoint(marker)),
    [markers]
  );
  const {enqueueSnackbar} = useSnackbar();
  useEffect(() => {
    fetch(url).then(res => res.json()).then(result => {
        setLoading(false);
        option.center = [result.results[0].position.lon, result.results[0].position.lat];
        option.zoom = 15;
        const newPoint = {coordinates: new data.Position(result.results[0].position.lon, result.results[0].position.lat), title: result.results[0].address.freeformAddress};
        setMarkers([...markers, newPoint]);
      },
      error => {
                 setLoading(false);

                if (error) {
                  enqueueSnackbar('Cannot load map', {variant: 'error'});
                }
               });
  }, {});

  if (loading) {
    return <LoadingPaper />;
  }

  return React.createElement(AzureMapsProvider, null, React.createElement('div', {style: {height: '300px'}}, React.createElement(AzureMap, {options: option, controls: controls}, React.createElement(AzureMapDataSourceProvider, {events: {dataadded: e => {
                console.log('Data on source added', e);
              }}, options: {cluster: true, clusterRadius: 2}}, React.createElement(
            AzureMapLayerProvider,
            {
              options: layerOptions,
              lifecycleEvents: {
                layeradded: () => {
                  console.log('LAYER ADDED TO MAP');
                },
              },
              type: markersLayer,
            }
          ), memoizedMarkerRender))));

};


export {MapView as default};
