import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

export default ({values, set_values}) => {

  const on_change = (event) => {
    set_values({...values, [event.target.name]: event.target.value});
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Unit details
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            name="ClientKey"
            label="Client Key"
            fullWidth
            autoComplete="off"
            value={values.ClientKey}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            name="AccountNumber"
            label="Account Number"
            fullWidth
            autoComplete="off"
            value={values.AccountNumber}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            name="MeterNumber"
            label="Meter Number"
            fullWidth
            autoComplete="off"
            value={values.MeterNumber}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            name="PremiseNumber"
            label="Premise Number"
            fullWidth
            autoComplete="off"
            value={values.PremiseNumber}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin='dense'
            name="CustomerName"
            label="Customer name"
            fullWidth
            autoComplete="off"
            value={values.CustomerName}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            margin='dense'
            name="HouseNumber"
            label="House Number"
            fullWidth
            autoComplete="off"
            value={values.HouseNumber}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            margin='dense'
            name="StreetName"
            label="Street"
            fullWidth
            autoComplete="off"
            value={values.StreetName}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            name="CityName"
            label="City"
            fullWidth
            autoComplete="off"
            value={values.CityName}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            name="StateCode"
            label="State"
            fullWidth
            autoComplete="off"
            value={values.StateCode}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            name="PostalCode"
            label="Postal Code"
            fullWidth
            autoComplete="off"
            value={values.PostalCode}
            onChange={on_change}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin='dense'
            name="PhoneNumber"
            label="Phone Number"
            fullWidth
            autoComplete="off"
            value={values.PhoneNumber}
            onChange={on_change}
          />
        </Grid>
      </Grid>
    </>
  );
};
