import React, {useEffect, useState} from "react";
import {DropzoneArea} from 'material-ui-dropzone';
import csv_parse from 'csv-parse';
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import titleize from "titleize";
import humanizeString from "humanize-string";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    root:              {},
    dropZoneAreaError: {
      borderColor: theme.palette.error.main
    }
  };
});

const CSVPicker = ({mapped_fields, required_fields, do_submit, submit_done, set_extracted_data}) => {
  const classes = useStyles();
  const [is_adding_field, set_is_adding_field] = useState(false);
  const [has_submitted, set_has_submitted] = useState(false);
  const [active_fields, set_active_fields] = useState({});
  const [file, set_file] = useState(null);
  const [csv, set_csv] = useState([]);
  const [errors, set_errors] = useState({required_fields: false, no_fields: false});

  useEffect(() => {
    set_errors({
                 required_fields: !required_fields.reduce((a, i) => a &&
                                                                    active_fields.hasOwnProperty(i) &&
                                                                    active_fields[i] !== '', true),
                 no_fields:       (Object.keys(active_fields).length === 0)
               }
    );
  }, [mapped_fields, active_fields, required_fields]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function({target: {result: csv}}) {
        csv_parse(csv,
                  {columns: true},
                  (err, data) => {
                    set_csv(data);
                  });
      }
      reader.readAsText(file);
    }
  }, [file]);

  const on_submit = () => {
    if (!do_submit) {
      return;
    }
    if (csv &&
        active_fields &&
        !errors.no_fields &&
        !errors.required_fields) {
      const data = csv.map((row) => {
        let r = {};
        Object.keys(active_fields).forEach((field) => {
          r[field] = row[active_fields[field]];
        });
        return r;
      });
      set_extracted_data(data);
    }
    set_has_submitted(true);
    submit_done();
  };
  useEffect(on_submit,
            [
              do_submit,
              set_extracted_data,
              csv,
              active_fields,
              set_has_submitted
            ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DropzoneArea acceptedFiles={['.csv']}
                      filesLimit={1}
                      dropzoneText='Drop or Click to Upload CSV'
                      onChange={(files) => set_file(files[0])}
                      dropzoneClass={clsx((has_submitted && !file) && classes.dropZoneAreaError)}/>
        {has_submitted && !file &&
         <Typography color="error" variant="caption">
           Must select a file
         </Typography>
        }
      </Grid>
      <Grid item xs={12}>
        <Box display="flex"
             justifyContent='space-between'
             alignItems='center'>
          <Typography variant='h5'>Fields</Typography>
          <IconButton onClick={() => set_is_adding_field(true)}><AddIcon/></IconButton>
        </Box>
        {has_submitted && errors.no_fields &&
         <Typography color="error" variant="caption" display='block'>
           Must select at least one field
         </Typography>
        }
        {has_submitted && errors.required_fields &&
         <Typography color="error" variant="caption" display='block'>
           Required Field Missing
         </Typography>
        }
      </Grid>
      {
        Object.keys(active_fields).map((field) => (
          <Grid item xs={12} key={field}>
            <Box display="flex"
                 justifyContent='space-between'
                 alignItems='center'>
              <TextField
                select
                label={`Field For ${titleize(humanizeString(field))}`}
                margin='dense'
                disabled={!file}
                name={field}
                value={active_fields[field]}
                onChange={({target: {name, value}}) => set_active_fields((prev) => {
                  return {
                    ...prev,
                    [name]: value
                  };
                })}
                error={has_submitted && active_fields[field] === ''}
                helperText={(has_submitted && active_fields[field] === '') ? 'Must select a field mapping' : null}
                fullWidth
              >
                <MenuItem key="unset" value=''>Not Set</MenuItem>
                {csv.length > 0 && Object.keys(csv[0]).map((col) => (
                  <MenuItem key={col} value={col}>
                    {titleize(humanizeString(col))}
                  </MenuItem>
                ))}
              </TextField>
              <IconButton
                onClick={() => set_active_fields((prev) => {
                  let r = {...prev};
                  delete r[field];
                  return r;
                })}>
                <DeleteIcon/>
              </IconButton>
            </Box>
          </Grid>
        ))
      }
      {
        is_adding_field &&

        <Grid item xs={12}>
          <TextField
            select
            label="Field For"
            margin='dense'
            disabled={!file}
            name='new_field'
            value=''
            onChange={({target: {name, value}}) => {
              set_active_fields((prev) => ({...prev, [value]: ''}));
              set_is_adding_field(false);
            }}
            fullWidth
          >
            {mapped_fields.filter((f) => !active_fields.hasOwnProperty(f))
                          .map((f) => (
                            <MenuItem key={f} value={f}>
                              {titleize(humanizeString(f))}
                            </MenuItem>
                          ))}
          </TextField>
        </Grid>
      }
    </Grid>
  );
};

export default CSVPicker;