import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {useSnackbar} from "notistack";
import {useMutate} from "restful-react";
import MaterialTable from "material-table";
import material_table_icons from "../../common/material_table_icons";
import material_table_query from "../../common/material_table_query";
import AddBox from '@material-ui/icons/AddBox';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import DeepCopyIcon from '@material-ui/icons/FileCopy';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import ToggleIcon from "material-ui-toggle-icon";


const useStyles = makeStyles(theme => ({
  root:    {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

export default () => {
  const classes = useStyles();
  let history = useHistory();
  let table_ref = useRef();
  const {enqueueSnackbar} = useSnackbar();
  const [show_hidden, set_show_hidden] = useState(false);
  const {
    mutate: copy_project,
    error:  copy_error
  } = useMutate('PUT', `/data/projects`);
  useEffect(() => {
    if (copy_error) {
      enqueueSnackbar('Cannot copy project', {variant: 'error'});
    }
  }, [copy_error, enqueueSnackbar]);
  const {
    mutate: show_hide_project,
    error:  show_hide_error
  } = useMutate('PUT', `/data/projects`);
  useEffect(() => {
    if (show_hide_error) {
      enqueueSnackbar('Cannot set project visibility', {variant: 'error'});
    }
  }, [show_hide_error, enqueueSnackbar]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <MaterialTable
          title={
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item>
                <AssignmentIcon/>
              </Grid>
              <Grid item>
                Projects
              </Grid>
            </Grid>
          }
          tableRef={table_ref}
          icons={material_table_icons}
          columns={[
            {
              title: 'ID',
              field: 'id',
              type:  "numeric"
            },
            {
              title:  'Name',
              field:  'name',
              render: (row) => (<Link to={`/projects/${row.id}`}>{row.name}</Link>)
            },
            {
              title: 'Status',
              field: 'status'
            },
            {
              title: 'Created',
              field: 'createdAt',
              type:  'date'
            },
            {
              title:  'Owner',
              field:  'owner',
              render: (row) => row.owner.display_name
            },
            {
              title:  'Show/Hide',
              field:  'hidden',
              render: (row) => (
                <IconButton onClick={async () => {
                  await show_hide_project({},
                                          {
                                            queryParams: {
                                              action:    'toggle_visibility',
                                              projectId: row.id
                                            }
                                          });
                  enqueueSnackbar('Project visibility changed', {variant: 'success'});
                  table_ref.current.onQueryChange();
                }}>
                  <ToggleIcon on={!row.hidden}
                              offIcon={<VisibilityIcon color='action'/>}
                              onIcon={<VisibilityOffIcon color='action'/>}/>
                </IconButton>
              )
            }
          ]}
          data={material_table_query({
                                       url:          '/api/data/projects',
                                       data_key:     'projects',
                                       on_error:     () => history.push('/'),
                                       query_params: {show_hidden}
                                     })}
          actions={[
            {
              icon:         () => <ToggleIcon on={show_hidden}
                                              offIcon={<VisibilityIcon color='action'/>}
                                              onIcon={<VisibilityOffIcon color='action'/>}/>,
              tooltip:      show_hidden ? 'Hide Hidden Projects' : 'Show Hidden Projects',
              isFreeAction: true,
              onClick:      (event) => {
                set_show_hidden(!show_hidden);
                table_ref.current.onQueryChange();
              }
            },
            {
              icon:         () => <AddBox color='primary'/>,
              tooltip:      'Add Project',
              isFreeAction: true,
              onClick:      (event) => history.push('/projects/new')
            },
            {
              icon:     () => <CopyIcon color='action'/>,
              tooltip:  'Copy Project',
              position: "row",
              onClick:  async (event, row) => {
                await copy_project({},
                                   {
                                     queryParams: {
                                       action:    'copy',
                                       projectId: row.id
                                     }
                                   });
                enqueueSnackbar('Project copied', {variant: 'success'});
                table_ref.current.onQueryChange();
              }
            },
            {
              icon:     () => <DeepCopyIcon color='action'/>,
              tooltip:  'Copy Project With Units',
              position: "row",
              onClick:  async (event, row) => {
                await copy_project({},
                                   {
                                     queryParams: {
                                       action:     'copy',
                                       with_units: true,
                                       projectId:  row.id
                                     }
                                   });
                enqueueSnackbar('Project copied', {variant: 'success'});
                table_ref.current.onQueryChange();
              }
            },
            {
              icon:    () => <VisibilityOffIcon color='action'/>,
              tooltip: 'Show/Hide Projects',
              onClick: async (event, rows) => {
                for (const row of rows) {
                  await show_hide_project({},
                                          {
                                            queryParams: {
                                              action:    'toggle_visibility',
                                              projectId: row.id
                                            }
                                          });
                }
                enqueueSnackbar('Project visibility changed', {variant: 'success'});
                table_ref.current.onQueryChange();
              }
            }
          ]}
          options={{
            actionsColumnIndex:  -1,
            search:              false,
            emptyRowsWhenPaging: false,
            selection:           true,
            pageSize:            25,
            pageSizeOptions:     [25, 50, 100]
          }}
        />

      </div>
    </div>
  );
};
