/**
 * Get a nested property via dotted path
 * @param {object} obj Object to access
 * @param {string} path Dotted path notation e.g. "a.b"
 * @returns {*} Property value
 */
export const get_dotted_property = (obj, path) => {
  const key = path.split('.');
  return (key.length > 1) ?
         obj[key[0]][key[1]] :
         obj[key[0]];
};

/**
 * Set a nested property via dotted path
 * @param {object} obj Object to access
 * @param {string} path Dotted path notation e.g. "a.b"
 * @param {*} value Property value
 */
export const set_dotted_property = (obj, path, value) => {
  const key = path.split('.');
  if (key.length > 1) {
    obj[key[0]][key[1]] = value;
  } else {
    obj[key[0]] = value;
  }
};
