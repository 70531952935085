const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

const AnyCaseInclusion = (value, options) => {
  return ((options.haystack || []).findIndex((i) => i.toLowerCase() === value.toLowerCase()) >= 0) ?
         null : "is not a valid value";
};

const YesOrNo = (value) => {
  return (['Y', 'N', 'yes', 'no'].findIndex((i) => i.toLowerCase() === value.toLowerCase()) >= 0) ?
         null : 'must be yes or no';
};

export default {
  checked,
  AnyCaseInclusion,
  YesOrNo
};
